import _, { chain, findKey, keys, map, max, min, pickBy } from "lodash";
import { BookmarkCheck, Table as DTable, LayoutPanelTop, ListTodo, Users } from "lucide-react";
import moment from "moment-timezone";
import React, { useCallback, useEffect } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Button, ButtonGroup, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";
import { useFilteredDockets } from "../../hooks/useFilteredDockets";
import useUrlParams from "../../hooks/useUrlParams";
import MenuItemsPopUp from "../../oms/MenuItemPopup";
import IntervalRangeFilter from "../../resbutler-ui/components/Elements/IntervalRangeFilter";
import MultiToggle, { MultiToggleOption } from "../../resbutler-ui/components/Elements/MultiToggle";
import { getMealLabelIds } from "../../resbutler-utils/utils";
import { IntervalRange, intervalRangeFilter } from "../../resbutler-utils/utils/filters";
import { CHANGE_SETTING } from "../../store/actions/settings.actions";
import { TOGGLE_SUMMARY } from "../../store/actions/themes.actions";
import { StateType } from "../../store/reducers/reducers";
import { getOperationModeSelector, OrderDisplayMode, setDisplay, setFilterPrinterIds, setIntervalRange, setOrderDisplayMode, setOrderMode, setProgress } from "../../store/reducers/rootSlice";
import { getToggleSummarySelector } from "../../store/reducers/themes.reducers";
import ToggleOptions from "../Common/ToggleOptions";
import { ON_CLICK_DEBOUNCE_WAIT } from "./../../constants";
import { OperationMode } from "./Offsidebar";

export enum DisplayType {
  PrepDisplay = 0,
  ServeDisplay = 1,
}

export enum ProgressType {
  Active = 0,
  Completed = 1,
  All = 2,
}

export enum OrderModeType {
  MenuPlan = 0,
  SentOrder = 1,
  BasketOrder = 2,
}

const OmsSelector = React.memo<{ hiddenKey: string }>(function OmsSelector({ hiddenKey }) {
  const [restaurantId, date] = useUrlParams() as any;

  const history = useHistory();
  const dispatch = useDispatch();
  const { restaurants, orderModeType, operationMode, printers, display, progress, showSummary, labels, meals, intervalRange, orderDisplayType, bookings } = useSelector(
    (state: StateType) => ({
      restaurants: state.root.restaurants,
      orderModeType: state.root.orderModeType,
      operationMode: getOperationModeSelector(state as any),
      printers: state.root.printers,
      display: state.root.display,
      progress: state.root.progress,
      showSummary: getToggleSummarySelector(state),
      labels: state.root.labels,
      meals: state.root.meals,
      intervalRange: state.root.intervalRange,
      orderDisplayType: state.root.orderDisplayMode,
      bookings: state.root.bookings,
    }),
    shallowEqual
  );

  const { completedDocketNumber, activeDockets, allCount } = useFilteredDockets(hiddenKey);

  const mealLabels = getMealLabelIds(labels, restaurants[restaurantId].zoneId, date, restaurantId);
  const restaurantMeals = pickBy(meals, (meal, mId) => meal.restaurantId === restaurantId && meal.enabled && findKey(mealLabels, (ml) => ml.mealId === mId) !== undefined);

  useEffect(() => {
    const printerIds = keys(printers);
    dispatch(setFilterPrinterIds(printerIds));
  }, [printers]);

  const getTodayBtnStyle = (date: any, currentDate: any) => {
    const style: any = { borderColor: "#27c24c ", height: "35px", width: "100%" };
    if (date === currentDate) {
      style.backgroundColor = "#27c24c";
      style.color = "white";
    } else {
      style.backgroundColor = "#fff";
      style.color = "#27c24c";
    }
    return style;
  };

  const renderTodayDateTime = () => {
    const currentDate = moment().tz(restaurants[restaurantId]?.zoneId)?.format("YYYYMMDD");
    return (
      <Button style={getTodayBtnStyle(date, currentDate)} onClick={() => history.push(`${window.location.pathname}#${currentDate}/${restaurantId}`)}>
        Today
      </Button>
    );
  };

  const renderOrderModeToggle = () => {
    const options: MultiToggleOption[] = [
      {
        label: "Menu Plans",
        value: OrderModeType.MenuPlan,
        icon: ListTodo,
      },
      {
        label: "Sent Orders",
        value: OrderModeType.SentOrder,
        icon: BookmarkCheck,
      },
    ];

    return <MultiToggle options={options} selectedOption={orderModeType} onSelectOption={(value) => dispatch(setOrderMode(value))} />;
  };

  const renderProgress = () => (
    <UncontrolledDropdown className="btn-group" style={{ height: "35px", width: "100%" }}>
      <DropdownToggle caret>
        <span className="font-weight-bold ">
          {progress === ProgressType.All && <>All ({allCount})</>}
          {progress === ProgressType.Active && <>Active ({activeDockets})</>}
          {progress === ProgressType.Completed && <>Completed ({completedDocketNumber})</>}
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          value={ProgressType.All}
          onClick={() => {
            dispatch(setProgress(ProgressType.All));
          }}
        >
          All ({allCount})
        </DropdownItem>
        <DropdownItem
          value={ProgressType.Active}
          onClick={() => {
            dispatch(setProgress(ProgressType.Active));
          }}
        >
          Active ({activeDockets})
        </DropdownItem>
        <DropdownItem
          value={ProgressType.Completed}
          onClick={() => {
            dispatch(setProgress(ProgressType.Completed));
            dispatch({ type: CHANGE_SETTING, name: "showHiddenDockets", value: false });
          }}
        >
          Completed ({completedDocketNumber})
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderDisplay = () => (
    <UncontrolledDropdown className="btn-group" style={{ height: "35px", width: "100%" }}>
      <DropdownToggle caret>
        <span className="text">{display === DisplayType.PrepDisplay ? <b>Prep Display</b> : <b>Serve Display</b>}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          value={DisplayType.PrepDisplay}
          onClick={() => {
            dispatch(setDisplay(DisplayType.PrepDisplay));
          }}
        >
          Prep Display
        </DropdownItem>
        <DropdownItem
          value={DisplayType.ServeDisplay}
          onClick={() => {
            dispatch(setDisplay(DisplayType.ServeDisplay));
          }}
        >
          Serve Display
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderDateTime = () => {
    return (
      <ButtonGroup className="maestro-date" style={{ height: "35px", width: "100%" }}>
        <Button onClick={_.debounce(() => history.push(`${window.location.pathname}#${moment(date, "YYYYMMDD").add(-1, "days").format("YYYYMMDD")}/${restaurantId}`), ON_CLICK_DEBOUNCE_WAIT)}>
          <i className="fas fa-chevron-left"></i>
        </Button>
        <Datetime className=" maestro-selector-bold " dateFormat="ddd DD MMMM YYYY" closeOnSelect onChange={(e) => history.push(`${window.location.pathname}#${moment(e).format("YYYYMMDD")}/${restaurantId}`)} timeFormat={false} value={moment(date, "YYYYMMDD")} />
        <Button onClick={_.debounce(() => history.push(`${window.location.pathname}#${moment(date, "YYYYMMDD").add(1, "days").format("YYYYMMDD")}/${restaurantId}`), ON_CLICK_DEBOUNCE_WAIT)}>
          <i className="fas fa-chevron-right"></i>
        </Button>
      </ButtonGroup>
    );
  };

  const toggle = useCallback(() => {
    dispatch({ type: TOGGLE_SUMMARY, showSummary: !showSummary });
  }, [showSummary]);

  const renderToggleSummary = () => {
    return <ToggleOptions onClick={toggle} on={"Summary"} off={"Summary"} active={showSummary} width={"100%"} />;
  };

  const handleTimeChange = (range: IntervalRange) => {
    dispatch(setIntervalRange(range));
  };

  const filteredBookings = _.chain(bookings)
    .pickBy((booking) => intervalRangeFilter(booking, intervalRange))
    .value();

  const totalPax = filteredBookings ? _.values(filteredBookings).reduce((sum, b) => sum + b.pax, 0) : 0;

  return (
    <>
      <Card className="oms-selector card-default" style={{ marginTop: "5px", backgroundColor: "#555E65", padding: "5px" }}>
        <CardBody style={{ padding: "0px" }}>
          {/* top row */}
          <div className="grid-container">
            <div className="grid-item">
              <div className="btn-group d-flex">
                <div className=" bg-info">{renderDateTime()}</div>
                <div className="flex-grow-1 ml-1">{renderTodayDateTime()}</div>
              </div>
            </div>
            <div className="grid-item">
              <MenuItemsPopUp restaurantId={restaurantId} date={date} />
            </div>
            <div className="grid-item">{renderOrderModeToggle()}</div>
            <div className="grid-item">
              <div className="btn-group d-flex">
                <div className="w-50">{orderModeType === OrderModeType.SentOrder && operationMode === OperationMode.AllStep ? renderDisplay() : null}</div>
                <div className="w-50 ml-1">{orderModeType === OrderModeType.SentOrder ? renderProgress() : null}</div>
              </div>
            </div>
            <div className="grid-item">
              <div className="btn-group w-100 justify-content-end">
                <div className="w-50 ml-1 ">{renderToggleSummary()}</div>
              </div>
            </div>
          </div>

          {/* 2nd row */}
          {orderModeType !== OrderModeType.SentOrder && (
            <div className="d-grid grid-container">
              <div className="grid-item text-nowrap d-flex align-content-center">
                <MultiToggle
                  options={[
                    { value: OrderDisplayMode.Docket, label: "Docket", icon: LayoutPanelTop },
                    { value: OrderDisplayMode.Table, label: "List", icon: DTable },
                  ]}
                  color="primary"
                  size="md"
                  onSelectOption={(value) => dispatch(setOrderDisplayMode(value))}
                  selectedOption={orderDisplayType}
                  classNames="mr-4"
                />
                <h4 className="pt-2">
                  <Users size={20} className="" />: <span className=" font-weight-bold pl-2 pr-1">{totalPax}</span>
                  <span className=" font-weight-light">pax</span>
                </h4>
              </div>
              <div className="grid-item-2">
                {!_.isEmpty(restaurantMeals) ? (
                  <IntervalRangeFilter //
                    minInterval={min(map(restaurantMeals, "activityStartTime"))}
                    maxInterval={max(map(restaurantMeals, (item) => (item.activityEndTime < item.activityStartTime ? item.activityEndTime + 24 * 60 * 60 * 1000 : item.activityEndTime)))}
                    step={30}
                    onChange={handleTimeChange}
                    markers={chain(restaurantMeals)
                      .map((item) => ({
                        label: item.name,
                        start: item.activityStartTime,
                        end: item.activityEndTime < item.activityStartTime ? item.activityEndTime + 24 * 60 * 60 * 1000 : item.activityEndTime,
                      }))
                      .sortBy("order")
                      .value()}
                    selectedRange={intervalRange}
                  />
                ) : (
                  <Spinner size="md" color="white">
                    {""}
                  </Spinner>
                )}
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
});

export default withRouter(OmsSelector);
