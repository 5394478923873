export enum PaymentProviderResponseResult {
  Unknown = 0,
  Approved = 1,
  Cancelled = 2,
  Reversed = 3,
  Declined = 4,
  SystemError = 5,
  NotStarted = 6,
  Processing = 7,
  Busy = 8,
  Timeout = 9,
  Offline = 10,
  Failed = 11, //generic response
}

export enum PaymentProvidersType {
  SPI = 0,
  Tyro = 1,
  Linkly = 2,
}

export interface PaymentProvider {
  name: string;
  type: PaymentProvidersType;
  restaurantId: string;
  contact?: number;
  merchantId?: string;
  username?: string;
  password?: string;
}

export interface PaymentProviders {
  [paymentProviderId: string]: PaymentProvider;
}

export enum TyroResponseResult {
  Unknown = "UNKNOWN",
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Reversed = "REVERSED",
  Declined = "DECLINED",
  SystemError = "SYSTEM ERROR",
  NotStarted = "NOT STARTED",
}
export interface PaymentProviderCompletionResponse {
  result: PaymentProviderResponseResult;
  resultText: string;
  purchaseId: string;
  totalAmount: number;
  cashoutAmount: number;
  serviceFee: number;
  paymentTabId?: string;
  transactionFee: number;
  refundHandlingFee?: number;
  cashReceived: number;
  refundAmount: number;
  tipAmount: number;
  tipAmountAddedOutsideWizbutler?: number;
  purchaseAmount: number;
  customerReceipt?: string;
  merchantReceipt?: string;
  cardLastDigit?: string;
  cardBrandName?: string;
  merchantCountry?: string;
  currency?: string;
  timeStamp?: number;
  transactionRefId?: string;
  transactionLocationType?: string;
  bankReference?: string;
  note?: string;
  refundChargeId?: string;
  manualCreditCardTypeId?: number;
  manualResponse?: boolean;
  bankBsbNumber?: string;
  bankAccountLastDigit?: string;
  bankDebitMandateId?: string;
  showRePrintReceiptOnEftpos?: boolean;
}

export interface Mx51TransactionCompletionResponse {
  surcharge_amount?: number;
  cash_amount?: number;
  tip_amount?: number;
  bank_noncash_amount: number;
  bank_cash_amount: number;
  masked_pan: string;
  scheme_name: string;
  currency: string;
  merchant_country: string;
  schema_app_name: string;
  schema_name: string;
  merchant_receipt: string;
  customer_receipt: string;
  // more are there
}

export interface TyroTransactionCompletionResponse {
  authorisationCode: string;
  baseAmount: string;
  cardType: string;
  customerReceipt: string;
  elidedPan: string;
  issuerActionCode: string;
  result: TyroResponseResult;
  rrn: string;
  surchargeAmount?: string;
  tipAmount?: string;
  transactionAmount: string;
  transactionId: string;
  transationReference: string;
  cashReceived?: number;
  refundAmount?: number;
}
