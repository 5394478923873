import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { catchExceptionCallback, getConfig } from "../core/utilities";
import { setUser } from "../store/reducers/rootSlice";

function useUser() {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const data = window.location.hash ? window.location.hash.slice(window.location.hash.indexOf("#") + 1).split("/") : [];
  const restaurantId = data[1];
  let date = data[0];
  if (!date) date = moment().format("YYYYMMDD");

  useEffect(() => {
    let unsubscribe = null as any;
    async function load() {
      try {
        setLoading(true);
        const data = getConfig();
        if (data) {
          window.document.title = `OMS - ${data.pageTitle}`;
          if (firebase.apps.length) {
            await firebase.app().delete();
          }
          firebase.initializeApp(data.config);
          unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
              if (window.location.pathname !== "/login") window.location.href = `/login?returnUrl=${window.location.pathname}`;
            } else {
              setCurrentUser(user);
              const { tenantId } = getConfig();
              const userRecord = await user?.getIdTokenResult();
              const userSnap = await firebase.database().ref(`${tenantId}/users`).child(userRecord?.claims.user_id).once("value");
              if (userSnap.exists()) {
                dispatch(setUser(userSnap.val()));
                const userRestaurantId = userSnap.val().defaultRestaurant;
                history.push(`${window.location.pathname}#${date}/${restaurantId ? restaurantId : userRestaurantId}`);
              }
              setLoading(false);
            }
          });
        } else {
          window.location.href = `/login?returnUrl=${window.location.pathname}`;
        }
      } catch (error) {
        catchExceptionCallback(error);
      }
    }
    load();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return [loading, currentUser];
}

export default useUser;
