import _, { keys } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { OperationMode } from "../components/Layout/Offsidebar";
import { DisplayType, OrderModeType, ProgressType } from "../components/Layout/OmsSelector";
import { isDocketCompleted } from "../helpers/dockets";
import { OMSDisplayDocket, OMSDocketGeneratedFrom } from "../resbutler-utils/types/Docket";
import { OrderItemStatus } from "../resbutler-utils/types/Order";
import { StateType } from "../store/reducers/reducers";
import { getOperationModeSelector } from "../store/reducers/rootSlice";

export const useFilteredDockets = (hiddenKey: string) => {
  const { display, linkedTable, operationMode, progress, orderMode, dockets, showHiddenDockets } = useSelector(
    (state: StateType) => ({
      display: state.root.display,
      linkedTable: state.root.linkedTable,
      operationMode: getOperationModeSelector(state),
      progress: state.root.progress,
      orderMode: state.root.orderModeType,
      dockets: state.root.dockets,
      showHiddenDockets: state.settings.showHiddenDockets,
    }),
    shallowEqual
  );

  const tableLinkFilter = (docket) => linkedTable === docket.tableNo;

  let filteredDockets = dockets.filter((d) => d.docketGeneratedFrom === OMSDocketGeneratedFrom.Docket) as OMSDisplayDocket[];
  const originalDockets = filteredDockets;
  if (linkedTable) {
    filteredDockets = dockets.filter(tableLinkFilter);
  }

  if (orderMode === OrderModeType.MenuPlan) {
    filteredDockets = dockets;
  } else if (orderMode === OrderModeType.BasketOrder) {
    filteredDockets = dockets;
  } else if (orderMode === OrderModeType.SentOrder) {
    if (operationMode === OperationMode.OneStep) {
      if (progress === ProgressType.Active) {
        filteredDockets = filteredDockets.filter((docket) => docket.docketItems.some((di) => di.orderStatus <= OrderItemStatus.Prepared));
      } else if (progress === ProgressType.Completed) {
        filteredDockets = filteredDockets.filter((docket) => isDocketCompleted(docket, operationMode, display));
      }
    } else if (operationMode === OperationMode.AllStep) {
      if (display === DisplayType.PrepDisplay) {
        if (progress === ProgressType.Active) {
          filteredDockets = filteredDockets.filter((docket) => docket.docketItems.some((di) => di.orderStatus <= OrderItemStatus.Sent));
        } else if (progress === ProgressType.Completed) {
          filteredDockets = filteredDockets.filter((docket) => isDocketCompleted(docket, operationMode, display));
        }
      } else if (display === DisplayType.ServeDisplay) {
        if (progress === ProgressType.Active) {
          filteredDockets = filteredDockets.filter((docket) => docket.docketItems.some((di) => di.orderStatus <= OrderItemStatus.Prepared));
        } else if (progress === ProgressType.Completed) {
          filteredDockets = filteredDockets.filter((docket) => isDocketCompleted(docket, operationMode, display));
        }
      }
    }
  }

  // show hidden dockets on the active progress filter
  if (showHiddenDockets) {
    filteredDockets = filteredDockets.filter((docket) => docket.hidden[hiddenKey]);
  } else {
    filteredDockets = filteredDockets.filter((docket) => !docket.hidden[hiddenKey]);
  }

  const completedDocketNumber = _.filter(originalDockets, (d) => {
    return isDocketCompleted(d, operationMode, display);
  }).length;

  const allCount = keys(originalDockets).length;
  const activeDockets = keys(originalDockets).length - completedDocketNumber;

  return { filteredDockets, completedDocketNumber, allCount, activeDockets };
};
