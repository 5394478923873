import "firebase/compat/auth";
import "firebase/compat/database";
import moment from "moment";

function useUrlParams() {
  const data = window.location.hash ? window.location.hash.slice(window.location.hash.indexOf("#") + 1).split("/") : [];
  const restaurantId = data[1];
  let date = data[0];
  if (!date) date = moment().format("YYYYMMDD");

  return [restaurantId, date] as [string, string];
}

export default useUrlParams;
