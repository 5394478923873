import { ConfigByEmail } from "../../resbutler-utils/types/Endpoints";
import { get } from "lodash";

export function getConfig(): ConfigByEmail {
  const dataUnserialized = window.localStorage.getItem("butler:data");
  return (dataUnserialized ? { ...JSON.parse(dataUnserialized) } : null) as ConfigByEmail;
}

export const isLocalhost = () => {
  return window.location.hostname === "localhost";
};

export default class Utilities {
  static validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}

export const getEnvConfig = (name: string) => {
  return get(process.env, name) || get(import.meta.env, name);
};
