import { Icon } from "@fortawesome/fontawesome-svg-core";
import { faEyeSlash, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "firebase/compat/app";
import "rc-tree-select/assets/index.css";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { ProgressType } from "../../components/Layout/OmsSelector";
import { catchExceptionCallback, getConfig } from "../../core/utilities";
import { useHiddenDockets } from "../../hooks/useHiddenDockets";
import { TOGGLE_SETTING } from "../../store/actions/settings.actions";
import { StateType } from "../../store/reducers/reducers";
import { setLinkedTable } from "../../store/reducers/rootSlice";
import { initialStockLimits, UPDATE_STOCK_LIMIT } from "../../store/reducers/stockLimit.reducer";
import MenuItemModal from "./MenuItemModal";
import TableLinkModal from "./TableLinkModal";

const style: any = {
  button: { position: "relative", whiteSpace: "nowrap" },
  buttonCount: {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    borderRadius: "0.625rem",
    backgroundColor: "#f05050",
    color: "#fff",
    padding: "0.1875rem 0.4375rem",
    fontSize: "0.75rem",
    zIndex: 9,
  },
};

function MenuItemsPopUp({ restaurantId, date }) {
  const dispatch = useDispatch();
  const { hiddenDocketCount } = useHiddenDockets();

  const { linkedTable, dockets, showHiddenDockets, progress, stockLimits } = useSelector((state: StateType) => {
    return {
      linkedTable: state.root.linkedTable,
      dockets: state.root.dockets,
      showHiddenDockets: state.settings.showHiddenDockets,
      progress: state.root.progress,
      stockLimits: state.stockLimits,
    };
  }, shallowEqual);

  const [showMenuItemModal, setShowMenuItemModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const overrideCount = Object.values(stockLimits.count).reduce((accu: any, curr: any) => accu + curr) as number;

  async function getStockLimits() {
    try {
      setLoading(true);

      const snapshot = await firebase.database().ref(`${tenantId}/maestros/${date}/${restaurantId}/date/stockLimits`).once("value");

      dispatch({ type: UPDATE_STOCK_LIMIT, data: snapshot.exists() ? snapshot.val() : initialStockLimits });
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getStockLimits();
  }, [restaurantId, date]);

  const { tenantId } = getConfig();

  const tableNumbers = Array.from(new Set(dockets.map((d) => d.tableNo).filter((x) => !!x))).sort();

  const [showTableLinkModal, setShowTableLinkModal] = useState(false);

  const handleTableOnClick = (tableNumber, isActive) => {
    dispatch(setLinkedTable(isActive ? null : tableNumber));
    setShowTableLinkModal(false);
  };

  const onToggleHiddenDockets = () => {
    dispatch({ type: TOGGLE_SETTING, name: "showHiddenDockets" });
  };

  return (
    <div className="btn-group d-flex">
      <Button className="flex-grow-1" type="button" style={style.button} onClick={(e) => setShowMenuItemModal(!showMenuItemModal)} disabled={loading}>
        {loading ? "Initializing data..." : "Menu Control"}
        <span className="badge" style={style.buttonCount}>
          {overrideCount > 0 ? overrideCount : ""}
        </span>
      </Button>
      <Button className="flex-grow-1 ml-1" type="button" color={linkedTable ? "danger" : "secondary"} style={style.button} onClick={() => setShowTableLinkModal(!showTableLinkModal)} disabled={loading}>
        {loading ? (
          <Spinner size={"sm"}>{""}</Spinner>
        ) : (
          <>
            Table Link <FontAwesomeIcon icon={faLink as Icon} />
          </>
        )}
      </Button>

      <Button className="ml-1" type="button" color={showHiddenDockets ? "danger" : "secondary"} style={{ ...style.button, width: 50 }} disabled={loading || [ProgressType.Completed].includes(progress)} onClick={onToggleHiddenDockets}>
        {loading ? (
          <Spinner size={"sm"}>{""}</Spinner>
        ) : (
          <>
            <FontAwesomeIcon icon={faEyeSlash as Icon} />
            {!!hiddenDocketCount && (
              <span className="badge badge-danger" style={style.buttonCount}>
                {hiddenDocketCount}
              </span>
            )}
          </>
        )}
      </Button>

      {showMenuItemModal && !loading && <MenuItemModal setShowModal={setShowMenuItemModal} restaurantId={restaurantId} date={date} />}
      {showTableLinkModal && <TableLinkModal tableNumbers={tableNumbers} setShowModal={setShowTableLinkModal} linkedTable={linkedTable} handleTableOnClick={handleTableOnClick} />}
    </div>
  );
}

// https://reactjs.org/docs/react-api.html#reactmemo
export default MenuItemsPopUp;
