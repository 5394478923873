import Stripe from "stripe";
import { Orders } from "./Order";
import { Mx51TransactionCompletionResponse, PaymentProviderCompletionResponse, TyroTransactionCompletionResponse } from "./PaymentProvider";
import { CustomerTips, PaymentPriceAdjustmentPayload, PaymentTypeId, TillDepositTabAllocations, TransactionSurcharge } from "./transaction";

export enum PurchaseTypeId {
  Purchase = 1,
  Refund = 2,
}
export enum PurchaseRequestStatus {
  Completed = 0,
  Pending = 1 /*When response has not come back yet from provider*/,
  CompletedSuccessfully = 2,
  Processing = 3 /** When response has come back from provider but is still on processing/pending state on provider */,
}
export type Purchase = {
  _key?: string;
  bookingId?: string;
  functionId?: string;
  terminalId?: string;
  merchantId?: string;
  tillId?: string;
  requestData?: { payload: PurchaseModel | RefundModel };
  cashReceived?: number;
  rawResponse?: TyroTransactionCompletionResponse | Mx51TransactionCompletionResponse | Stripe.Refund | Stripe.Charge;
  response?: PaymentProviderCompletionResponse;
  requestStatus: PurchaseRequestStatus;
  retries?: number;
  restaurantId: string;
  id?: string;
  paymentTypeId: PaymentTypeId;
  typeId: PurchaseTypeId;
  operatorId?: string;
  createdAt: number;
  invoiceId?: string;
  accountGroupId?: string;
  accountProductId?: string;
  manualResponse?: boolean;
  isSplitPending?: boolean;
};

export interface CancelPaymentModel {
  tillId: string;
}

export interface CancelModel {
  date: string;
  restaurantId: string;
  sessionId: string;
  mealId: string;
  operatorId: string;
  tillId: string;
}
//
export type PurchaseModel = {
  cashReceived: number;
  sessionId: string;
  tipAmount: number;
  closeTransaction: boolean;
  paymentTypeId?: PaymentTypeId;
  tillId: string;
  terminalId: string;
  merchantId?: string;
  transactionNumber: number;
  orderNumber: number;
  purchaseAmount: number;
  customerTips?: CustomerTips;
  cashoutAmount: number;
  serviceFee: number;
  promptForCashout: boolean;
  orders?: Orders;
  operatorId: string;
  operatorName?: string;
  redemptionCode?: any;
  label: { labelId: string; version: string };
  menuId?: string;
  date: number;
  closeTabId?: string;
  paymentTabId?: string;
  mealId: string;
  restaurantId: string;
  bookingId?: string;
  functionBookingId?: string;
  functionType?: string;
  customerId?: string;
  giftCertificateCode?: string;
  paymentProviderResponse?: PaymentProviderCompletionResponse;
  rawResponse?: TyroTransactionCompletionResponse | Mx51TransactionCompletionResponse;
  typeId: PurchaseTypeId;
  suppressMerchantPassword?: boolean;
  resbutlerFee?: number;
  accountGroupId?: string;
  accountProductId?: string;
  paymentPriceAdjustmentsPayload: PaymentPriceAdjustmentPayload[];
  depositTabAllocations?: TillDepositTabAllocations;
  depositTabId?: string;
  splitItemPay?: boolean;
  eComPaymentMethodId?: string;
  usePendingSplitPurchase?: boolean;
  note?: string;
};

export interface FormRefundDataLineItem {
  id: string;
  menuId?: string;
  quantity: number;
  productId: string;
  productSizeId: string;
  refundAmount: number;
  selected?: boolean;
}
export interface FormRefundDataLineItems {
  [id: string]: FormRefundDataLineItem;
}

export interface RefundModel {
  paymentKey: string;
  date: string;
  mealId: string;
  operatorPin: string;
  restaurantId: string;
  tillId: string;
  terminalId: string;
  merchantId?: string;
  transactionNumber: number;
  transactionId: string;
  refundLineItemsAndValues: FormRefundDataLineItem[];
  totalRefundLineItemValue: FormRefundDataLineItem;
  transactionFee?: number;
  serviceFee?: number;
  tipAmount?: number;
  description: string;
  suppressMerchantPassword?: boolean;
  operatorId: string;
  refundAmount: number;
  sessionId: string;
  paymentTypeId: PaymentTypeId;
  paymentProviderResponse?: PaymentProviderCompletionResponse;
  rawResponse?: TyroTransactionCompletionResponse | Mx51TransactionCompletionResponse;
  typeId: PurchaseTypeId;
  refundSurcharges: TransactionSurcharge[];
  refundServiceCharge: TransactionSurcharge;
  refundHandlingFee: number;
  deferredBookingVoucherLineItemValue?: FormRefundDataLineItem;
  tabId?: string;
  tabTransactionId?: string;
  tabTransactionPaymentId?: string;
}

export interface SettlementModel {
  tillId: string;
  isSettlementEnquiry: boolean;
  date?: number;
  format?: string;
  type?: string;
}
