// rootSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import pako from "pako";
import { createSelector } from "reselect";
import { OperationMode } from "../../components/Layout/Offsidebar";
import { DisplayType, OrderModeType, ProgressType } from "../../components/Layout/OmsSelector";
import { catchExceptionCallback, getConfig } from "../../core/utilities";
import { Labels, Meals } from "../../resbutler-utils/types";
import { Bookings } from "../../resbutler-utils/types/Booking";
import { OMSDisplayDocket } from "../../resbutler-utils/types/Docket";
import { BatchSettings, MenuHeadings, Menus } from "../../resbutler-utils/types/Menu";
import { Operators } from "../../resbutler-utils/types/Operator";
import { BookingOrderingData, Docket, Orders } from "../../resbutler-utils/types/Order";
import { Printers } from "../../resbutler-utils/types/Printers";
import { Restaurants } from "../../resbutler-utils/types/Restaurant";
import { WizUser } from "../../resbutler-utils/types/WizUser";
import { AdditionGroup, Modifier, PreparationGroup, Product, ProductGroups, ProductSizes, UpsellGroup } from "../../resbutler-utils/types/product";
import { IntervalRange } from "../../resbutler-utils/utils/filters";
import { OPERATION_MODE } from "../actions/root.actions";
import { StateType } from "./reducers";

export enum OrderDisplayMode {
  Docket = 1,
  Table = 2,
}
export interface RootReducerTypes {
  display: DisplayType;
  maestroMode: number;
  progress: ProgressType;
  restaurants: Restaurants;
  menus: Menus;
  batchSettings: BatchSettings;
  omsWidth: number;
  orders: Orders;
  orderModeType: OrderModeType;
  operationMode: OperationMode;
  highlightItemKey: string;
  allProducts: Product[];
  modifierGroups: Modifier[];
  preparationGroups: PreparationGroup[];
  upsellGroups: UpsellGroup[];
  additionGroups: AdditionGroup[];
  filterPrinterIds: string[];
  printers: Printers;
  operators: Operators;
  linkedTable: string;
  showSummary: boolean;
  dockets: OMSDisplayDocket[];
  productSizes: ProductSizes;
  menuHeadings: MenuHeadings;
  productGroups: ProductGroups;
  bookingsOrderingData: BookingOrderingData[];
  user: WizUser;
  labels: Labels;
  meals: Meals;
  intervalRange: IntervalRange;
  orderDisplayMode: OrderDisplayMode;
  bookings: Bookings;
  bookingOrders: Orders;
  removedDockets: Docket[];
}

const initialState: RootReducerTypes = {
  display: DisplayType.PrepDisplay,
  maestroMode: 0,
  progress: ProgressType.Active,
  restaurants: {},
  menus: {},
  batchSettings: {},
  orders: {},
  omsWidth: 0,
  orderModeType: OrderModeType.SentOrder,
  operationMode: null,
  highlightItemKey: "",
  allProducts: [],
  modifierGroups: [],
  preparationGroups: [],
  upsellGroups: [],
  additionGroups: [],
  filterPrinterIds: [],
  printers: {},
  operators: {},
  linkedTable: null,
  showSummary: false,
  dockets: [],
  productSizes: {},
  menuHeadings: {},
  productGroups: {},
  bookingsOrderingData: [],
  user: null,
  labels: {},
  meals: {},
  intervalRange: {},
  orderDisplayMode: OrderDisplayMode.Docket,
  bookings: {},
  bookingOrders: {},
  removedDockets: [],
};

export const getAllProducts = createAsyncThunk("root/getAllProducts", async () => {
  const { config, client } = getConfig();
  const response = await fetch(`https://storage.googleapis.com/${config.publicStorageBucket}/${client}/productData/products.json`);
  const arrayBuffer = await response.arrayBuffer();
  return JSON.parse(new TextDecoder().decode(pako.inflate(arrayBuffer)));
});

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    setOrderMode: (state, action) => {
      state.orderModeType = action.payload;
    },
    setOperationMode: (state, action) => {
      localStorage.setItem(OPERATION_MODE, JSON.stringify(action.payload));
      state.operationMode = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setRestaurants: (state, action) => {
      state.restaurants = action.payload;
    },
    setMenus: (state, action) => {
      state.menus = action.payload;
    },
    setBatchSettings: (state, action) => {
      state.batchSettings = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setOMSWidth: (state, action) => {
      state.omsWidth = action.payload;
    },
    setHighlightItemKey: (state, action) => {
      state.highlightItemKey = action.payload;
    },
    setModifierGroups: (state, action) => {
      state.modifierGroups = action.payload;
    },
    setPreparationGroups: (state, action) => {
      state.preparationGroups = action.payload;
    },
    setUpsellGroups: (state, action) => {
      state.upsellGroups = action.payload;
    },
    setAdditionGroups: (state, action) => {
      state.additionGroups = action.payload;
    },
    setFilterPrinterIds: (state, action) => {
      state.filterPrinterIds = action.payload;
    },
    setPrinters: (state, action) => {
      state.printers = action.payload;
    },
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
    setLinkedTable: (state, action) => {
      state.linkedTable = action.payload;
    },
    setDockets: (state, action) => {
      state.dockets = action.payload;
    },
    setProductSizes: (state, action) => {
      state.productSizes = action.payload;
    },
    setMenuHeadings: (state, action) => {
      state.menuHeadings = action.payload;
    },
    setProductGroups: (state, action) => {
      state.productGroups = action.payload;
    },
    setBookingsOrderingData: (state, action) => {
      state.bookingsOrderingData = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setDataLabels: (state, action) => {
      state.labels = action.payload;
    },
    setMeals: (state, action) => {
      state.meals = action.payload;
    },
    setIntervalRange: (state, action) => {
      state.intervalRange = action.payload;
    },
    setOrderDisplayMode: (state, action) => {
      state.orderDisplayMode = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    setBookingOrders: (state, action) => {
      state.bookingOrders = action.payload;
    },
    setRemovedDockets: (state, action) => {
      state.removedDockets = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.allProducts = action.payload;
    });
    builder.addCase(getAllProducts.rejected, (state, action) => {
      catchExceptionCallback(action.error);
    });
  },
});

export const { setDisplay, setOrderMode, setOperationMode, setProgress, setRestaurants, setMenus, setBatchSettings, setOrders, setOMSWidth, setHighlightItemKey, setModifierGroups, setPreparationGroups, setUpsellGroups, setAdditionGroups, setFilterPrinterIds, setPrinters, setOperators, setLinkedTable, setDockets, setProductSizes, setMenuHeadings, setProductGroups, setBookingsOrderingData, setUser, setDataLabels, setMeals, setIntervalRange, setOrderDisplayMode, setBookingOrders, setBookings, setRemovedDockets } = rootSlice.actions;

export const getOperationModeSelector = createSelector(
  (state: StateType) => state.root.operationMode,
  () => {
    const operationModeLocal = localStorage.getItem(OPERATION_MODE);
    return operationModeLocal ? JSON.parse(operationModeLocal) : OperationMode.OneStep;
  }
);

export default rootSlice.reducer;
