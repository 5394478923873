import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { keys, startCase } from "lodash";
import PropTypes from "prop-types";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from "reactstrap";
import { catchExceptionCallback } from "../../core/utilities";
import * as actions from "../../store/actions/actions";
import { toggleSetting } from "../../store/actions/settings.actions";
import { getOperationModeSelector, setFilterPrinterIds, setOperationMode } from "../../store/reducers/rootSlice";
import ToggleOptions from "../Common/ToggleOptions";
import useUrlParams from "./../../hooks/useUrlParams";
import { StateType } from "./../../store/reducers/reducers";
import { SettingReducerTypes } from "./../../store/reducers/settings.reducer";

export enum OperationMode {
  AllStep,
  OneStep,
}

const Offsidebar = () => {
  const { settings, operationMode, filterPrinterIds, printers } = useSelector(
    (state: StateType) => ({
      settings: state.settings,
      operationMode: getOperationModeSelector(state),
      filterPrinterIds: state.root.filterPrinterIds,
      printers: state.root.printers,
    }),
    shallowEqual
  );

  const options = [
    {
      displayName: "One Step",
      value: OperationMode.OneStep,
    },
    {
      displayName: "All Steps",
      value: OperationMode.AllStep,
    },
  ];
  const [restaurantId] = useUrlParams();
  const [offsidebarReady, setOffsidebarReady] = useState<boolean>(false);
  const [loadingLogout, setLoadingLogout] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onClick = useCallback((e) => dispatch(setOperationMode(e.target.checked ? options[1].value : options[0].value)), []);

  function logout() {
    setLoadingLogout(true);
    firebase
      .auth()
      .signOut()
      .then(() => {
        setLoadingLogout(false);
        window.location.href = "/login";
      })
      .catch((error) => {
        catchExceptionCallback(error);
        setLoadingLogout(false);
      });
  }

  useEffect(() => {
    setOffsidebarReady(true);
  }, [restaurantId, settings.offsidebarOpen]);

  const closeSidebar = (e: any) => {
    if (e.target.classList.contains("offsidebar")) {
      dispatch(toggleSetting("offsidebarOpen"));
    }
  };

  const renderOperationMode = () => {
    return <ToggleOptions onClick={onClick} on={options[1].displayName} off={options[0].displayName} width="100%" active={operationMode === OperationMode.AllStep} />;
  };
  const settingsMap = [
    { key: "showCustomerName", off: "Hide" },
    { key: "splitItemsByTable", off: "Don't split" },
    { key: "showTableServiceDockets", off: "Hide" },
    { key: "showPickupDockets", off: "Hide" },
    { key: "showCounterServiceDockets", off: "Hide" },
    { key: "showVacatedBookingDockets", off: "Hide" },
  ];

  const printerTreeData = keys(printers).map((key) => ({
    key,
    title: printers[key].name,
    value: key,
  }));

  const renderPrinters = () => {
    return (
      <TreeSelect
        className="form-control tree-select"
        choiceTransitionName="rc-tree-select-selection__choice-zoom"
        dropdownStyle={{ height: 100, overflow: "auto" }}
        dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
        treeLine
        value={filterPrinterIds}
        placeholder={"Select Printers"}
        showIcon={false}
        maxTagTextLength={25}
        autoClearSearchValue
        treeData={printerTreeData}
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        maxTagCount={20}
        onChange={(value) => {
          dispatch(setFilterPrinterIds(value));
        }}
        maxTagPlaceholder={(valueList) => {
          return `+${valueList.length}`;
        }}
      />
    );
  };

  return offsidebarReady ? (
    <aside className="offsidebar" onClick={closeSidebar}>
      <div className="aside-inner">
        {/* START Off Sidebar (right) */}
        <div className="container mt-4">
          <Row className="align-content-center mb-4">
            <div className="container-fluid font-weight-bold ms-2 mb-1">Printer Filters</div>
            <div className="w-100 mx-3">{renderPrinters()}</div>
          </Row>
          <Row className="align-content-center">
            <div className="container-fluid font-weight-bold ms-2 mb-1">Display Preference</div>
            <div className="container-fluid my-1">{renderOperationMode()}</div>
            <>
              {settingsMap.map((setting) => {
                const onValue = startCase(setting.key);
                const offValue = setting.off + onValue.slice(onValue.indexOf(" "));
                return (
                  <div key={setting.key} className="container-fluid my-1">
                    <ToggleOptions onClick={(e) => dispatch(actions.changeSetting(setting.key as keyof SettingReducerTypes, e.target.checked))} on={onValue} off={offValue} width="100%" active={settings[setting.key]} />
                  </div>
                );
              })}
            </>
          </Row>
          <Row className="row-bordered">
            <Col lg={12} xs={6} className="mt-5">
              <Button type="button" style={{ color: "white", backgroundColor: "black" }} className="btn-lg col-lg-12 float-right align-bottom" onClick={() => logout()}>
                <em className="icon-logout"></em>
                <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
                  Logout {loadingLogout && <Spinner size="sm">{""}</Spinner>}
                </span>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </aside>
  ) : null;
};

Offsidebar.propTypes = {
  settings: PropTypes.object,
};

export default Offsidebar;
