import { ProductCategoryId } from "./ProductCategoryId";
import { RestaurantTillCashupPeriodType } from "./Restaurant";
import { EftposCalculation, TerminalTransaction, Till } from "./Till";
import { PettyCashTransaction, Transaction } from "./transaction";

export const StripeTillKeyForReport = "stripe";
export interface StripeReport {
  [type: string]: {
    items: {
      [brand: string]: { amount: number; count: number; centsAmount: number; stripeFee: number; centsStripeFee: number; resbutlerFee: number; centsResbutlerFee: number };
    };
    count: number;
    refundCount: number;
    subTotal: number;
    stripeFee: number;
    resbutlerFee: number;
    paymentServiceFee: number;
    stripeTax: number;
    centsStripeTax: number;
    centsStripeFee: number;
    centsSubtotal: number;
    centsResbutlerFee: number;
    centsRefundAmount: number;
    tipAmount: number;
    refundAmount: number;
    netBalance: number;
    total: number;
  };
}

export interface PrePaidUtilisedTillReport {
  subTotal: number;
  refundAmount: number;
  count: number;
  refundCount: number;
}
export interface PrePaidUtilisedReport {
  tills: {
    [tillId: string]: PrePaidUtilisedTillReport;
  };
  subTotal: number;
  refundAmount: number;
  total: number;
  netBalance: number;
  count: number;
  refundCount: number;
  name: string;
  isTipReport?: boolean;
  managersCashupCategory?: string;
  productGroupId?: string;
}

export interface RevenueReportItem {
  subItems?: RevenueReportItems;
  subItems2?: RevenueReportItems;
  amount: number;
  count: number;
  order?: number;
  productCategoryName?: string;
  productCategoryOrder?: number;
  productCategoryId?: ProductCategoryId;
  gstAmount: number;
  managersCashupRevenueCategory?: ManagersCashupRevenueCategory | string;
  productGroupId?: string;
}
export interface RevenueReportItems {
  [name: string]: RevenueReportItem;
}
export interface RevenuReportItemsHeading {
  items: RevenueReportItems;
  amount: number;
  count: number;
}
export interface RevenueTillReport {
  sales: RevenuReportItemsHeading;
  prePayments: RevenuReportItemsHeading;
  tip: RevenuReportItemsHeading;
  tipGST: RevenuReportItemsHeading;
  salesGST: RevenuReportItemsHeading;
  prePaymentsGST: RevenuReportItemsHeading;

  expenses: RevenuReportItemsHeading;
  balanceSheetChanges: RevenuReportItemsHeading;

  amount: number;
  count: number;
}
export interface RevenueReport {
  tills: {
    [tillId: string]: RevenueTillReport;
  };
  salesAmount: number;
  salesCount: number;
  tipGSTAmount: number;
  prePaymentsAmount: number;
  prePaymentCount: number;

  tipAmount: number;
  tipCount: number;

  prePaymentsGSTAmount: number;
  salesGSTAmount: number;

  expensesAmount: number;
  balanceSheetAmount: number;

  amount: number;
  count: number;
}

export interface TillEftposPaymentBrandReport {
  amount: number;
  count: number;
  refundAmount: number;
  refundCount: number;
  tipAmount: number;
  tipCount: number;
  cashoutAmount: number;
  cashoutCount: number;
  order?: number;
  wizbutlerTransactions: Transaction[];
  terminalTransactions: TerminalTransaction[];
}
export interface TillEftposPaymentReport {
  items: { [brand: string]: TillEftposPaymentBrandReport};
  subTotal: number;
  refundAmount: number;
  total: number;
  netBalance: number;
  count: number;
  refundCount: number;
  tipAmount: number;
  tipCount: number;
  cashoutAmount: number;
  cashoutCount: number;
  wizbutlerTransactions: Transaction[];
  terminalTransactions: TerminalTransaction[];
  countingDifference: number;
  paymentServiceFee?: number;
}
export interface EftposPaymentReport {
  tills: {
    [tillId: string]: TillEftposPaymentReport;
  };
  subTotal: number;
  refundAmount: number;
  total: number;
  netBalance: number;
  count: number;
  refundCount: number;
  tipAmount: number;
  tipCount: number;
  cashoutAmount: number;
  cashoutCount: number;
  countingDifference: number;
  wizbutlerTransactions: Transaction[];
  terminalTransactions: TerminalTransaction[];
  paymentServiceFee?: number;
}

export interface PettyCashPaymentReport {
  tills: {
    [tillId: string]: {
      items: { [itemName: string]: { amount: number; refundAmount: number; count: number; refundCount: number; wizbutlerTransactions: PettyCashTransaction[]; order?: number } };
      subTotal: number;
      refundAmount: number;
      count: number;
      refundCount: number;
      wizbutlerTransactions: PettyCashTransaction[];
      total: number;
      netBalance: number;
    };
  };
  subTotal: number;
  refundAmount: number;
  total: number;
  netBalance: number;
  count: number;
  refundCount: number;
  name: string;
  wizbutlerTransactions: PettyCashTransaction[];
}

export interface TillAccountPaymentReport {
  items: { [itemName: string]: { amount: number; refundAmount: number; count: number; refundCount: number; order?: number } };
  subTotal: number;
  refundAmount: number;
  count: number;
  refundCount: number;
  total: number;
  netBalance: number;
}
export interface AccountsPaymentReport {
  tills: {
    [tillId: string]: TillAccountPaymentReport;
  };
  subTotal: number;
  refundAmount: number;
  total: number;
  netBalance: number;
  count: number;
  refundCount: number;
  name: string;
}

export interface TillCashPaymentReport extends TillAccountPaymentReport {
  pettyCashAmount: number;
  floatDifference: number;
  countingDifference: number;
}
export interface CashPaymentReport extends AccountsPaymentReport {
  tills: {
    [tillId: string]: TillCashPaymentReport;
  };
  pettyCashAmount: number;
  floatDifference: number;
  countingDifference: number;
}

export interface TillCashCountReport {
  tills: {
    [tillId: string]: {
      items: { [periodName: string]: { mealId?: string; netCash: number; floatDifference: number; pettyCashAmount: number; operatorNote: string } };
      netCash: number;
      floatDifference: number;
      pettyCashAmount: number;
    };
  };
  netCash: number;
  floatDifference: number;
  pettyCashAmount: number;
  tillCashupPeriodType: RestaurantTillCashupPeriodType;
  date?: string;
}

export interface TillEftposCountReport {
  tills: {
    [tillId: string]: {
      items: { [periodName: string]: { mealId?: string; operatorNote?: string; amount: number; cards: EftposCalculation[] } };
      amount: number;
    };
  };
  amount: number;
  tillCashupPeriodType: RestaurantTillCashupPeriodType;
  date?: string;
}

export interface FunctionBookingsCashupSplit {
  [functionBookingId: string]: FunctionBookingCashupSplit;
}
export interface FunctionBookingCashupSplit {
  food: number;
  beverage: number;
  other: number;
  discount: number;
  serviceCharge: number;
  surcharge: number;
  grandTotal: number;
  remainingGrandTotal: number;
  barTabUsed: number;
  barTab: number;
  totalGST: number;
  adjustment: number;
  foodGST: number;
  beverageGST: number;
  barTabGST: number;
  otherGST: number;
  surchargeGST: number;
  serviceChargeGST: number;
  adjustmentGST: number;
  discountGST: number;
}
export interface CountingDifference {
  [tillId: string]: number;
}

export interface DailyCashupReport {
  stripeTillKeyForReport: string;
  stripeReport: StripeReport;
  tills: Till[];
  revenueReport: RevenueReport;
  prepaidUtilisedReports: PrePaidUtilisedReport[];
  cashReport: CashPaymentReport;
  eftposReport: EftposPaymentReport; // Eftpos Report on Cashup
  creditAccountReport: AccountsPaymentReport;
  contraReport: AccountsPaymentReport;
  outsideWizbutlerReport: AccountsPaymentReport;
  tipOnlyRevenueReport: RevenueReport;
  terminalEftposReport: EftposPaymentReport;
  wizbutlerEftposReport: EftposPaymentReport;
}

export interface WeeklyCashupDayReport {
  stripeTillKeyForReport: string;
  tills: Till[];
  revenueReport: RevenueReport;
  tipOnlyRevenueReport: RevenueReport;
  startTimeMs: number;
  endTimeMs: number;
  paymentReports: {
    inServiceECommTotal?: number;
    inServiceGiftCertificateTotal?: number;
    inServiceDeferredBookingVoucherTotal?: number;
    unearnedNewDeferredBookingVoucherTotal?: number;
    stripeReport: StripeReport;
    prepaidUtilisedReports: PrePaidUtilisedReport[];
    cashReport: CashPaymentReport;
    eftposReport: EftposPaymentReport; //Eftpos Report on Cashup
    wizbutlerEftposReport: EftposPaymentReport;
    terminalEftposReport: EftposPaymentReport;
    creditAccountReport: AccountsPaymentReport;
    contraReport: AccountsPaymentReport;
    outsideWizbutlerReport: AccountsPaymentReport;
    paymentTotal: number;
  };
  managersReport?: ManagersCashupReport;
}

export enum DownloadAccountReport {
  UnearnedTransaction = 1,
  OutSideWizbutler = 2,
  ContraTransaction = 3,
  CreditAccountTransaction = 4,
  FunctionDepositInvoice = 5,
  UnpaidFunctionBooking = 6,
  InServiceECommTransaction = 7,
}

export enum ManagersCashupRevenueCategory {
  Food = "Food",
  Beverage = "Beverage",
  FoodAndBevTotal = "Total Food & Beverage",
  TotalGST = "Total GST",
  VoucherDiscount = "Voucher Discount",
  FunctionDiscount = "Function Discount",
  FunctionOther = "Function Other",
  RestaurantServiceCharge = "Restaurant Service Charge",
  SurchargesAndServiceCharge = "Surcharges and Service Charge",
}

export enum ManagersCashupUtilisedCategory {
  GiftCertificate = "GiftCertificate Redeemed",
  DeferredBookingVoucher = "Defereed Booking Voucher Redeemed",
  RestaurantServiceCharge = "Restaurant Service Charge Utilised",
  UnpaidFunctionInvoice = "Unpaid Function Invoice",
  SurchargeAndServiceCharge = "Surcharge & Service Charge Utilised",
  PreOrders = "Pre Orders Utilised",
  Voucher = "Voucher Utilised",
  FunctionDepositUtilized = "Function Deposits Utilized",
}
export interface ManagersCashupReport {
  startTimeMs: number;
  endTimeMs: number;
  payments: {
    cash: { total: number; items: { [tillId: string]: { total: number } } };
    eftpos: { total: number; items: { [tillId: string]: { total: number } } };
    stripe: { total: number; domesticTotal: number; internationalTotal: number; bankTransferTotal: number };
    inServiceEComm: { total: number };
    prepaidUtilised: { total: number; items: { [tillId: string]: { total: number; order: number; productGroupId?: string } } };
    contraAccount: { total: number };
    creditAccount: { total: number };
    unpaidFunctionInvoice: { total: number };
    unearnedNewDeferredBookingVoucher: { total: number };
  };
  revenue: {
    total: number;
    totalIncGST: number;
    items: {
      [reportName: string | ManagersCashupRevenueCategory]: {
        amount: number;
        gstAmount: number;
        order?: number;
        productGroupId?: string;
      };
    };
  };
}
