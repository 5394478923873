import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import React from "react";
import { HierarchicalMenu } from "react-instantsearch";

const SearchMenu = React.memo(() => {
  const transformItemsRecursively = (items) => {
    return map(items, (item) => {
      return {
        ...item,
        count: null,
        label: (
          <span className="custom-hierarchical-item">
            <span className="item-icon">
              <FontAwesomeIcon icon={item.data && item.data.length > 0 ? faAngleDown : (faAngleRight as any)} className="w-4 h-4" />
            </span>
            <span className="item-name">
              {item.label}
              <span className="item-count px-1">({item.count})</span>
            </span>
          </span>
        ),
        data: item.data ? transformItemsRecursively(item.data) : null,
      };
    });
  };

  return (
    <div className="custom-hierarchical-menu">
      <HierarchicalMenu attributes={["groups.lvl0", "groups.lvl1", "groups.lvl2"]} transformItems={transformItemsRecursively} />
    </div>
  );
});

export default SearchMenu;
