import { SearchClient } from "algoliasearch/lite";
import React, { useEffect, useRef } from "react";
import { InstantSearch, Pagination, useHits, useInstantSearch, useSearchBox } from "react-instantsearch";
import SearchMenu from "./SearchMenu";

const StateResults = React.memo(() => {
  const { status, results, indexUiState } = useInstantSearch();

  useEffect(() => {
    if (status === "stalled") {
      console.log("Search stalled at:", new Date().toISOString(), {
        indexUiState,
        hasResults: !!results,
        resultsCount: results?.nbHits,
      });
    }
  }, [status, indexUiState, results]);

  return null;
});

export interface DynamicAlgoliaSearchProps {
  searchClient: SearchClient;
  indexName?: string;
  renderSearchInput: (params: any) => React.ReactNode;
  renderHits: (params: any) => React.ReactNode;
  children: (params: any) => React.ReactNode;
}

const SearchBox = React.memo(({ renderInput }: { renderInput: (params: any) => React.ReactNode }) => {
  const { query, refine } = useSearchBox();

  return <>{renderInput({ query, refine })}</>;
});

const Hits = React.memo(({ renderHits }: { renderHits: (params: any) => React.ReactNode }) => {
  const { hits } = useHits();

  return <>{renderHits({ hits })}</>;
});

export const DynamicAlgoliaSearch = React.memo(({ searchClient, indexName, children, renderSearchInput, renderHits }: DynamicAlgoliaSearchProps) => {
  const [indexStatus, setIndexStatus] = React.useState<"checking" | "ready" | "error">("checking");

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const checkIndex = async () => {
      try {
        const index = searchClient.initIndex(indexName);
        await index.search("");
        timeoutRef.current = setTimeout(() => {
          setIndexStatus("ready");
        }, 100);
      } catch (error) {
        console.error("Index error:", error);
        setIndexStatus("error");
      }
    };
    checkIndex();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIndexStatus("error");
    };
  }, [indexName, searchClient]);

  if (indexStatus === "checking") {
    return <div>Checking search index...</div>;
  }

  if (indexStatus === "error") {
    return <div>Error connecting to search index</div>;
  }

  return (
    <InstantSearch key={`${indexName}-${indexStatus}`} indexName={indexName} searchClient={searchClient}>
      <StateResults />
      {children({
        renderSearchBox: () => <SearchBox renderInput={renderSearchInput} />,
        renderSearchMenu: () => <SearchMenu />,
        renderSearchHits: () => <Hits renderHits={renderHits} />,
        renderSearchPagination: () => <Pagination />,
      })}
    </InstantSearch>
  );
});
