import React from "react";
import {
  AccordionBodyProps,
  AccordionItemProps,
  AccordionProps,
  BadgeProps,
  ButtonDropdownProps,
  ButtonGroupProps,
  ButtonProps,
  CardBodyProps,
  CardFooterProps,
  CardHeaderProps,
  CardImgProps,
  CardProps,
  CardTextProps,
  CardTitleProps,
  CollapseProps,
  ColProps,
  ContainerProps,
  DropdownItemProps,
  DropdownMenuProps,
  DropdownProps,
  DropdownToggleProps,
  FormGroupProps,
  InputProps,
  LabelProps,
  ListGroupProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
  NavbarTogglerProps,
  NavItemProps,
  NavLinkProps,
  NavProps,
  PaginationItemProps,
  PaginationLinkProps,
  PaginationProps,
  PopoverBodyProps,
  PopoverHeaderProps,
  ProgressProps,
  RowProps,
  Accordion as RSAccordion,
  AccordionBody as RSAccordionBody,
  AccordionItem as RSAccordionItem,
  Badge as RSBadge,
  Button as RSButton,
  ButtonDropdown as RSButtonDropdown,
  ButtonGroup as RSButtonGroup,
  Card as RSCard,
  CardBody as RSCardBody,
  CardFooter as RSCardFooter,
  CardHeader as RSCardHeader,
  CardImg as RSCardImg,
  CardText as RSCardText,
  CardTitle as RSCardTitle,
  Col as RSCol,
  Collapse as RSCollapse,
  Container as RSContainer,
  Dropdown as RSDropdown,
  DropdownItem as RSDropdownItem,
  DropdownMenu as RSDropdownMenu, //
  DropdownToggle as RSDropdownToggle,
  FormGroup as RSFormGroup,
  Input as RSInput,
  Label as RSLabel,
  ListGroup as RSListGroup,
  Modal as RSModal,
  ModalBody as RSModalBody,
  ModalFooter as RSModalFooter,
  ModalHeader as RSModalHeader,
  Nav as RSNav,
  NavbarToggler as RSNavbarToggler,
  NavItem as RSNavItem,
  NavLink as RSNavLink,
  Pagination as RSPagination,
  PaginationItem as RSPaginationItem,
  PaginationLink as RSPaginationLink,
  PopoverBody as RSPopoverBody,
  PopoverHeader as RSPopoverHeader,
  Progress as RSProgress,
  Row as RSRow,
  Spinner as RSSpinner,
  TabContent as RSTabContent,
  Table as RSTable,
  TabPane as RSTabPane,
  Tooltip as RSTooltip,
  UncontrolledDropdown as RSUncontrolledDropdown,
  UncontrolledPopover as RSUncontrolledPopover,
  SpinnerProps,
  TabContentProps,
  TableProps,
  TabPaneProps,
  TooltipProps,
  UncontrolledDropdownProps,
  UncontrolledPopoverProps,
} from "reactstrap";

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <RSButton {...props}>{children}</RSButton>;
};

export const Col: React.FC<ColProps> = ({ children, ...props }) => {
  return <RSCol {...props}>{children}</RSCol>;
};

export const Row: React.FC<RowProps> = ({ children, ...props }) => {
  return <RSRow {...props}>{children}</RSRow>;
};

export const Card: React.FC<CardProps> = ({ children, ...props }) => {
  return <RSCard {...props}>{children}</RSCard>;
};

export const CardBody: React.FC<CardBodyProps> = ({ children, ...props }) => {
  return <RSCardBody {...props}>{children}</RSCardBody>;
};

export const CardImg: React.FC<CardImgProps> = ({ children, ...props }) => {
  return <RSCardImg {...props}>{children}</RSCardImg>;
};

export const CardText: React.FC<CardTextProps> = ({ children, ...props }) => {
  return <RSCardText {...props}>{children}</RSCardText>;
};

export const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return <RSTooltip {...props}>{children}</RSTooltip>;
};

export const CardTitle: React.FC<CardTitleProps> = ({ children, ...props }) => {
  return <RSCardTitle {...props}>{children}</RSCardTitle>;
};

export const CardHeader: React.FC<CardHeaderProps> = ({ children, ...props }) => {
  return <RSCardHeader {...props}>{children}</RSCardHeader>;
};

export const CardFooter: React.FC<CardFooterProps> = ({ children, ...props }) => {
  return <RSCardFooter {...props}>{children}</RSCardFooter>;
};

export const Spinner: React.FC<SpinnerProps> = ({ children, ...props }) => {
  return <RSSpinner {...props}>{children}</RSSpinner>;
};

export const Table: React.FC<TableProps> = ({ children, ...props }) => {
  return <RSTable {...props}>{children}</RSTable>;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, ...props }) => {
  return <RSModalHeader {...props}>{children}</RSModalHeader>;
};

export const ModalBody: React.FC<ModalBodyProps> = ({ children, ...props }) => {
  return <RSModalBody {...props}>{children}</RSModalBody>;
};

export const ModalFooter: React.FC<ModalFooterProps> = ({ children, ...props }) => {
  return <RSModalFooter {...props}>{children}</RSModalFooter>;
};

export const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  return <RSModal {...props}>{children}</RSModal>;
};

export const DropdownToggle: React.FC<DropdownToggleProps> = ({ children, ...props }) => {
  return <RSDropdownToggle {...props}>{children}</RSDropdownToggle>;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ children, ...props }) => {
  return <RSDropdownMenu {...props}>{children}</RSDropdownMenu>;
};

export const DropdownItem: React.FC<DropdownItemProps> = ({ children, ...props }) => {
  return <RSDropdownItem {...props}>{children}</RSDropdownItem>;
};

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, ...props }) => {
  return <RSButtonGroup {...props}>{children}</RSButtonGroup>;
};

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({ children, ...props }) => {
  return <RSButtonDropdown {...props}>{children}</RSButtonDropdown>;
};

export const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  return <RSContainer {...props}>{children}</RSContainer>;
};

export const Dropdown: React.FC<DropdownProps> = ({ children, ...props }) => {
  return <RSDropdown {...props}>{children}</RSDropdown>;
};

export const PaginationLink: React.FC<PaginationLinkProps> = ({ children, ...props }) => {
  return <RSPaginationLink {...props}>{children}</RSPaginationLink>;
};

export const PaginationItem: React.FC<PaginationItemProps> = ({ children, ...props }) => {
  return <RSPaginationItem {...props}>{children}</RSPaginationItem>;
};

export const Pagination: React.FC<PaginationProps> = ({ children, ...props }) => {
  return <RSPagination {...props}>{children}</RSPagination>;
};

export const Label: React.FC<LabelProps> = ({ children, ...props }) => {
  return <RSLabel {...props}>{children}</RSLabel>;
};

export const FormGroup: React.FC<FormGroupProps> = ({ children, ...props }) => {
  return <RSFormGroup {...props}>{children}</RSFormGroup>;
};

export const Badge: React.FC<BadgeProps> = ({ children, ...props }) => {
  return <RSBadge {...props}>{children}</RSBadge>;
};

export const NavLink: React.FC<NavLinkProps> = ({ children, ...props }) => {
  return <RSNavLink {...props}>{children}</RSNavLink>;
};

export const Nav: React.FC<NavProps> = ({ children, ...props }) => {
  return <RSNav {...props}>{children}</RSNav>;
};

export const NavItem: React.FC<NavItemProps> = ({ children, ...props }) => {
  return <RSNavItem {...props}>{children}</RSNavItem>;
};

export const TabContent: React.FC<TabContentProps> = ({ children, ...props }) => {
  return <RSTabContent {...props}>{children}</RSTabContent>;
};

export const TabPane: React.FC<TabPaneProps> = ({ children, ...props }) => {
  return <RSTabPane {...props}>{children}</RSTabPane>;
};

export const Collapse: React.FC<CollapseProps> = ({ children, ...props }) => {
  return <RSCollapse {...props}>{children}</RSCollapse>;
};

export const Input: React.FC<InputProps> = ({ children, ...props }) => {
  return <RSInput {...props}>{children}</RSInput>;
};

export const UncontrolledDropdown: React.FC<UncontrolledDropdownProps> = ({ children, ...props }) => {
  return <RSUncontrolledDropdown {...props}>{children}</RSUncontrolledDropdown>;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({ children, ...props }) => {
  return <RSAccordionItem {...props}>{children}</RSAccordionItem>;
};

export const AccordionBody: React.FC<AccordionBodyProps> = ({ children, ...props }) => {
  return <RSAccordionBody {...props}>{children}</RSAccordionBody>;
};

export const Accordion: React.FC<AccordionProps> = ({ children, ...props }) => {
  return <RSAccordion {...props}>{children}</RSAccordion>;
};

export const Progress: React.FC<ProgressProps> = ({ children, ...props }) => {
  return <RSProgress {...props}>{children}</RSProgress>;
};

export const ListGroup: React.FC<ListGroupProps> = ({ children, ...props }) => {
  return <RSListGroup {...props}>{children}</RSListGroup>;
};

export const NavbarToggler: React.FC<NavbarTogglerProps> = ({ children, ...props }) => {
  return <RSNavbarToggler {...props}>{children}</RSNavbarToggler>;
};

export const UncontrolledPopover: React.FC<UncontrolledPopoverProps> = ({ children, ...props }) => {
  return <RSUncontrolledPopover {...props}>{children}</RSUncontrolledPopover>;
};

export const PopoverHeader: React.FC<PopoverHeaderProps> = ({ children, ...props }) => {
  return <RSPopoverHeader {...props}>{children}</RSPopoverHeader>;
};

export const PopoverBody: React.FC<PopoverBodyProps> = ({ children, ...props }) => {
  return <RSPopoverBody {...props}>{children}</RSPopoverBody>;
};
