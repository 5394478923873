import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { Labels } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { catchExceptionCallback, getConfig } from "../core/utilities";
import { buildDataFromDocs } from "../resbutler-utils/utils";
import { setDataLabels } from "../store/reducers/rootSlice";

function useLabels(firebaseLoading): boolean[] {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetLabels = async () => {
      try {
        const { client } = getConfig();
        setLoading(true);
        const snap = await firebase.firestore().collection(`${client}/maestroDataLabels/maestroDataLabels`).where("enabled", "==", true).get();
        const results = buildDataFromDocs<Labels>(snap.docs);
        dispatch(setDataLabels(results));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    if (!firebaseLoading) fetLabels();
  }, [firebaseLoading]);

  return [loading];
}

export default useLabels;
