import { getMillisecondsFromDateInterval } from "./bookingUtils";

interface HasIntervalId {
  intervalId: number;
}

export interface IntervalFilterOptions<T> {
  date: string | number;
  zoneId: string;
  timePropName: { [K in keyof T]: T[K] extends string | number ? K : never }[keyof T];
}

export type IntervalRange = { start?: number; end?: number };

export const intervalRangeFilter = <T>(entity: T, intervalRange: IntervalRange, options?: IntervalFilterOptions<T>): boolean => {
  const { start, end } = intervalRange;

  if (!start && !end) return true;

  let isInMinRange = true,
    isInMaxRange = true;

  const getValue = (): number => {
    if (options) {
      return entity[options.timePropName] as unknown as number;
    }
    return (entity as unknown as HasIntervalId).intervalId;
  };

  if (start) {
    const from = options ? getMillisecondsFromDateInterval(Number(options.date), start, options.zoneId) : start;
    isInMinRange = getValue() >= from;
  }

  if (end) {
    const to = options ? getMillisecondsFromDateInterval(Number(options.date), end, options.zoneId) : end;
    isInMaxRange = getValue() <= to;
  }

  return isInMinRange && isInMaxRange;
};
