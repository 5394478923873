import { forEach } from "lodash";
import { CalendarClock } from "lucide-react";
import momentTz from "moment-timezone";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { FC } from "react";
import { IntervalRange } from "../../../resbutler-utils/utils/filters";
import styled from "styled-components";

// Maintain styles within this component
const SliderContainer = styled.div`
  width: 100%;

  .rc-slider {
    padding: 0px;
  }

  .rc-slider-mark-text {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    transform: translateX(0%) !important;
    white-space: nowrap;
    margin-top: -5px;
  }

  .rc-slider-rail {
    background-color: #e0e0e0;
  }

  .rc-slider-track {
    background-color: #2196f3;
  }

  .rc-slider-handle {
    border-color: #2196f3;
    background-color: white;
    opacity: 1;

    &:hover {
      border-color: #1976d2;
    }

    &:active {
      box-shadow: 0 0 5px #2196f3;
      border-color: #1976d2;
    }
  }
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  gap: 15px;
  align-items: center;
`;

export type IntervalRangeMarker = {
  label: string;
  start: number;
  end: number;
};

interface IntervalRangeFilterProps {
  minInterval: number; // milliseconds since start of day
  maxInterval: number; // milliseconds since start of day
  step: number; // minutes
  onChange?: (range: IntervalRange) => void; // parent's onChange handler
  markers?: IntervalRangeMarker[]; // used to display markers on sliders (eg Breakfast, LUnch...)
  selectedRange?: IntervalRange;
}
const IntervalRangeFilter: FC<IntervalRangeFilterProps> = ({ minInterval, maxInterval, step = 30, onChange, markers, selectedRange }) => {
  const getInterval = (value: number): number => {
    return value * step * 60 * 1000 + minInterval;
  };

  const getValue = (interval: number): number => {
    return (interval - minInterval) / 1000 / 60 / step;
  };
  const totalMinutes = (maxInterval - minInterval) / 1000 / 60;
  const stepsCount = Math.floor(totalMinutes / step);
  const marks = {};
  if (markers) {
    forEach(markers, (marker: IntervalRangeMarker) => {
      const indexStart = (marker.start === minInterval ? 0 : (marker.start - minInterval) / 1000 / 60) / step;
      marks[indexStart] = marker.label;
    });
  }

  const [range, setRange] = React.useState([getValue(selectedRange?.start) || 0, getValue(selectedRange?.end) || stepsCount]);

  const handleChange = (values) => {
    setRange(values);

    if (onChange) {
      onChange({ start: getInterval(values[0]), end: getInterval(values[1]) });
    }
  };

  const getTimeLabel = (value) => {
    return momentTz.tz(getInterval(value), "milliseconds").format("hh:mm A");
  };

  const sliderStyles = {
    rail: {
      background: "#e0e0e0",
      height: 4,
    },
    track: {
      background: "#2196f3",
      height: 4,
    },
    handle: {
      border: "2px solid #2196f3",
      opacity: 1,
      backgroundColor: "white",
      boxShadow: "0 0 5px rgba(0,0,0,0.1)",
      width: 20,
      height: 20,
      marginTop: -8,
      cursor: "pointer",
      ":hover": {
        border: "2px solid #1976d2",
        boxShadow: "0 0 0 6px rgba(33, 150, 243, 0.1)",
      },
      ":active": {
        boxShadow: "0 0 5px #2196f3",
        border: "2px solid #1976d2",
      },
    },
  };

  return (
    <div className="d-flex justify-content-between">
      <CalendarClock size="20" className="mr-2 mt-2" />
      <SliderContainer className="d-flex flex-column justify-content-between">
        <TimeDisplay>
          <span className="">{getTimeLabel(range[0])}</span>
          <Slider range min={0} max={stepsCount} step={1} value={range} onChange={handleChange} marks={marks} pushable={1} styles={sliderStyles} />
          <span>{getTimeLabel(range[1])}</span>
        </TimeDisplay>
      </SliderContainer>
    </div>
  );
};

export default IntervalRangeFilter;
