import { SearchClient } from "algoliasearch/lite";
import { filter, find, findIndex, keys, map } from "lodash";
import React from "react";
import { Configure } from "react-instantsearch";
import { AlgoliaSearchHit } from "../../../resbutler-utils/types";
import { ProductCategoryId } from "../../../resbutler-utils/types/ProductCategoryId";
import { Product, ProductSearchResult, ProductSize, ProductTypeId } from "../../../resbutler-utils/types/product";
import { getImagesFromProduct } from "../../../resbutler-utils/utils/menuUtils";
import { DynamicAlgoliaSearch } from "../DynamicAlgoliaSearch";
import { Button, Col, Row, Table } from "../WizElements/Elements";

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

interface HitsProp<T> {
  selected: ProductSearchResult[];
  setSelected: any;
  hits: AlgoliaSearchHit<T>[];
  sizes: ProductSize[];
  restaurantId: string;
  limitToOne: boolean;
  limitToOneSize: boolean;
  clientId: string;
  publicStorageBucket: string;
}

const Hits = React.memo(({ selected, setSelected, hits, sizes, restaurantId, limitToOne, limitToOneSize, clientId, publicStorageBucket }: HitsProp<Product>) => {
  const handleSelectAll = (selectAll: boolean) => {
    const newSelections = hits.map((hit) => {
      const selectedSizes = {};
      for (const [sizeId, isEnabled] of Object.entries(hit.sizes)) {
        const priceEnabled = isEnabled && hit?.restaurants?.[restaurantId]?.price?.[sizeId] !== undefined && hit.restaurants[restaurantId].enabled === true;
        selectedSizes[sizeId] = priceEnabled ? selectAll : false;
      }
      return {
        productId: hit.objectID,
        sizes: selectedSizes,
      };
    });
    // Combine existing selections (excluding current hits) with new selections
    const result = [...selected.filter((s) => !hits.some((h) => h.objectID === s.productId)), ...newSelections];
    setSelected(result);
  };

  return (
    <div className="mt-2">
      <Button className="my-2" type="button" color="primary" onClick={() => handleSelectAll(true)}>
        Select All
      </Button>
      <Button className="my-2 mx-3 btn btn-block border" onClick={() => handleSelectAll(false)}>
        De-select all
      </Button>
      <Table bordered>
        <tbody>
          {map(hits, (hit) => {
            const index = findIndex(selected, (s) => s.productId === hit.objectID);
            const { thumbnail } = getImagesFromProduct(hit, publicStorageBucket, clientId);
            return (
              <tr key={hit.objectID}>
                <td className="ps-3" style={{ borderBottom: "1px solid #ddd" }} width={"80%"}>
                  <div className="mb-1">{hit.name}</div>
                  {(hit.typeId === ProductTypeId.food || hit.typeId === ProductTypeId.beverage || hit.typeId === ProductTypeId.combinationFoodAndBeverage || hit.typeId === ProductTypeId.fixedPrice || hit.productCategoryId === ProductCategoryId.Inventory) && hit.sizes && (
                    <ul style={{ listStyleType: "none" }} className="p-0">
                      {keys(hit.sizes).map((sizeId) => {
                        if (hit.sizes[sizeId]) {
                          const productSize = find(sizes, (p) => p.id === sizeId);
                          const priceEnabled = hit?.restaurants?.[restaurantId]?.price?.[sizeId] !== undefined && hit.restaurants[restaurantId].enabled === true;
                          return (
                            <li key={`${hit.objectID}-${sizeId}`}>
                              <div className="checkbox c-checkbox">
                                <label className="mb-2" htmlFor={`${hit.objectID}-${sizeId}`}>
                                  <input
                                    id={`${hit.objectID}-${sizeId}`}
                                    type="checkbox"
                                    disabled={(!priceEnabled && restaurantId !== "") || findIndex(selected, (s: any) => s.uneditableSizes?.[sizeId] && s.productId === hit.objectID) > -1}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        if (limitToOne) {
                                          setSelected([
                                            {
                                              productId: hit.objectID,
                                              sizes: { [sizeId]: true },
                                            },
                                          ]);
                                        } else {
                                          if (index === -1)
                                            setSelected(
                                              selected.concat({
                                                productId: hit.objectID,
                                                sizes: {
                                                  ...(limitToOneSize ? {} : selected[index]?.sizes),
                                                  [sizeId]: true,
                                                },
                                              })
                                            );
                                          else
                                            setSelected([
                                              ...selected.slice(0, index),
                                              {
                                                ...selected[index],
                                                sizes: {
                                                  ...(limitToOneSize ? {} : selected[index]?.sizes),
                                                  [sizeId]: true,
                                                },
                                              },
                                              ...selected.slice(index + 1),
                                            ]);
                                        }
                                      } else {
                                        setSelected([
                                          ...selected.slice(0, index),
                                          {
                                            ...selected[index],
                                            sizes: {
                                              ...selected[index]?.sizes,
                                              [sizeId]: false,
                                            },
                                          },
                                          ...selected.slice(index + 1),
                                        ]);
                                      }
                                    }}
                                    checked={findIndex(selected, (s) => s.sizes?.[sizeId] && s.productId === hit.objectID) > -1 ? true : false}
                                  />
                                  <span className="fa fa-check" />
                                  {productSize?.name} {priceEnabled ? `(${formatter.format(hit.restaurants[restaurantId].price[sizeId])})` : ""}
                                </label>
                              </div>
                            </li>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  )}
                  {hit.typeId === ProductTypeId.fixedPriceMenu && (
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <div className="checkbox c-checkbox">
                          <label className="mb-2 pe-2" htmlFor={hit.objectID}>
                            <input
                              id={hit.objectID}
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (!limitToOne) setSelected([...selected.slice(0, index), { productId: hit.objectID }, ...selected.slice(index + 1)]);
                                  else setSelected([{ productId: hit.objectID }]);
                                } else {
                                  setSelected(filter(selected, (s) => s.productId !== hit.objectID));
                                }
                              }}
                              checked={findIndex(selected, (s) => s.productId === hit.objectID) > -1 ? true : false}
                            />
                            <span className="fa fa-check" />
                            {hit.fixedPriceMenuTypes.adult?.charAt(0).toUpperCase() + hit.fixedPriceMenuTypes.adult?.slice(1)}
                          </label>
                        </div>
                      </li>
                    </ul>
                  )}
                  {(Number(hit.typeId) === ProductTypeId.time || Number(hit.typeId) === ProductTypeId.financial || Number(hit.typeId) === ProductTypeId.depositMinSpend || Number(hit.typeId) === ProductTypeId.customerServiceExpense) && (
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <div className="checkbox c-checkbox">
                          <label className="mb-2 pe-2" htmlFor={hit.objectID}>
                            <input
                              id={hit.objectID}
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (!limitToOne) {
                                    if (index >= 0) {
                                      setSelected([...selected.slice(0, index), { productId: hit.objectID }, ...selected.slice(index + 1)]);
                                    } else {
                                      setSelected([...selected, { productId: hit.objectID }]);
                                    }
                                  } else {
                                    setSelected([{ productId: hit.objectID }]);
                                  }
                                } else {
                                  setSelected(filter(selected, (s) => s.productId !== hit.objectID));
                                }
                              }}
                              checked={findIndex(selected, (s) => s.productId === hit.objectID) > -1 ? true : false}
                            />
                            <span className="fa fa-check" />
                            {hit.name}
                          </label>
                        </div>
                      </li>
                    </ul>
                  )}
                </td>
                <td className="d-flex justify-content-end" style={{ borderBottom: "1px solid #ddd" }}>
                  <div className="search-img">{hit.image && <img src={thumbnail} style={{ maxWidth: 250 }} />}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
});

export interface ProductSearchProps {
  sizes?: ProductSize[];
  onChange?: any;
  query?: string;
  limitToOne?: boolean;
  restaurantId?: string;
  data?: ProductSearchResult[];
  searchClient: SearchClient;
  tenantId?: string;
  clientId: string;
  publicStorageBucket: string;
  limitToOneSize?: boolean;
}

export const ProductSearch = React.memo(({ sizes, onChange, query, limitToOne, restaurantId, data, searchClient, tenantId, clientId, publicStorageBucket, limitToOneSize }: ProductSearchProps) => {
  return (
    <DynamicAlgoliaSearch
      searchClient={searchClient}
      indexName={`${tenantId.replace("-", "_")}_products`}
      renderSearchInput={({ currentRefinement, isSearchStalled, refine }) => {
        return (
          <React.Fragment>
            <input type="search" value={currentRefinement} className="form-control" onChange={(event) => refine(event.currentTarget.value)} placeholder="Search Products" />
            {isSearchStalled ? "My search is stalled" : ""}
          </React.Fragment>
        );
      }}
      renderHits={(params) => {
        return <Hits sizes={sizes} restaurantId={restaurantId} selected={data} setSelected={onChange} limitToOne={limitToOne} limitToOneSize={limitToOneSize} clientId={clientId} publicStorageBucket={publicStorageBucket} {...params} />;
      }}
    >
      {({ renderSearchBox, renderSearchMenu, renderSearchHits, renderSearchPagination }) => {
        return (
          <React.Fragment>
            <Configure filters={query} />
            <Row>
              <Col lg={12}>{renderSearchBox()}</Col>
              <Col lg={4}>
                <div className="mt-2">{renderSearchMenu()}</div>
              </Col>
              <Col lg={8}>{renderSearchHits()}</Col>
            </Row>
            <Row>
              <Col lg={12}>{renderSearchPagination()}</Col>
            </Row>
          </React.Fragment>
        );
      }}
    </DynamicAlgoliaSearch>
  );
});
