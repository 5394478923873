import { map } from 'lodash';
import TreeSelect, { SHOW_PARENT } from 'rc-tree-select';
import { Field, useForm, useFormState } from 'react-final-form';
import { Col, FormGroup, Label, Row, Table } from 'reactstrap';
import { MenuHeadings, Menus } from '../../../../resbutler-utils/types/Menu';
import { ProductSize } from '../../../../resbutler-utils/types/product';

interface SpecialFormProps {
  stockLimitKey: any;
  product: any;
  menus: Menus;
  size: ProductSize;
  menuHeadings: MenuHeadings;
  updateOverrideMenuIds?: any;
  updateProducts?: any;
  restaurantId?: string;
}

const getMenuTree = (menus: Menus) =>
  Object.entries(menus || {}).map(([id, menu]) => ({
    key: id,
    title: menu.name,
    value: id,
    disabled: false,
  }));

const getMenuHeadingOptions = (menuId: string, menus: Menus, menuHeadings: MenuHeadings) => {
  const groupHeadings = Object.assign({}, menus[menuId]?.food?.groupHeadings, menus[menuId]?.beverage?.groupHeadings);
  const arrowChar = '→';

  const parseGroup = (groupHeadings, parentHierarchy = '') => {
    const data = [];
    Object.keys(groupHeadings).forEach((key) => {
      const groupHeading = groupHeadings[key];
      const label = parentHierarchy ? `${parentHierarchy} ${arrowChar} ${groupHeading.displayName}` : groupHeading.displayName;
      const id = !parentHierarchy ? groupHeading.menuHeadingId : groupHeading.subHeadingId;
      data.push({ label, id });
      if (Object.keys(groupHeading.groupHeadings).length > 0) {
        const nestedData = parseGroup(groupHeading.groupHeadings, label);
        data.push(...nestedData);
      }
    });
    return data;
  };
  return parseGroup(groupHeadings);
};

// const validateField = (value, menuId) => {
//   let errors = {} as any;
//   // if (!value.menuHeadingId) {
//   //   errors.menuHeadingId = 'Menu Heading is required';
//   // }
//   // if (value.price === undefined || !value.price.trim().length) {
//   //   errors.price = 'Override prices is required';
//   // }
//   return errors;
// };

const renderMenuTree = ({ input: { value }, onChange, treeData, meta: { error } }) => {
  return (
    <>
      {error && typeof error === 'string' && <span className="d-block validate-form">{error}</span>}
      <TreeSelect
        className="form-control tree-select w-100"
        style={{ width: '50%' }}
        choiceTransitionName="rc-tree-select-selection__choice-zoom"
        dropdownStyle={{ height: 100, overflow: 'auto' }}
        dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
        treeLine
        value={Object.keys(value ?? {})}
        placeholder={'Select menu'}
        showIcon={false}
        maxTagTextLength={25}
        autoClearSearchValue
        treeData={treeData}
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        maxTagCount={20}
        onChange={(ids) => {
          onChange(ids);
        }}
        maxTagPlaceholder={(valueList) => {
          return `+${valueList.length}`;
        }}
      />
    </>
  );
};

export default function SpecialForm({ stockLimitKey, product, menus = {}, size, menuHeadings, updateOverrideMenuIds, updateProducts, restaurantId }: SpecialFormProps) {
  const form = useForm();
  const values = useFormState().values;
  const menuTree = getMenuTree(menus);

  /**
   * Handle menu selection tree
   * @description based on selected menu ids of product's, will reevaluate
   * required operation for stock number
   *
   * @param  {string} ids product's menu unique Ids
   * @param  {object} formAPI react final form api
   * @param  {object} stocks form state values
   * @param  {string} productId product's unique Id
   * @param  {string} sizeId product's size Ids
   */
  function handleSelectedMenus(menuIds, stocks, productId, sizeId) {
    const selectedMenus = stocks[stockLimitKey]?.[productId]?.[sizeId]?.menus || {};
    let menuStocks = {};

    if (Object.keys(selectedMenus).length) {
      // new menu is selected
      if (menuIds.length > Object.keys(selectedMenus).length) {
        menuStocks = { ...selectedMenus };
        const newMenuIds = menuIds.filter((n) => !Object.keys(selectedMenus).includes(n));

        newMenuIds.forEach((id) => (menuStocks[id] = 0));
        form.change(`${stockLimitKey}.${productId}.${sizeId}.menus`, menuStocks);
      } else {
        if (!menuIds.length) {
          form.change(`${stockLimitKey}.${productId}.${sizeId}.menus`, {});
        } else {
          menuStocks = { ...selectedMenus };

          // get unchecked menus ids
          const uncheckedMenuIds = Object.keys(selectedMenus).filter((n) => !menuIds.includes(n));

          uncheckedMenuIds.forEach((menuId) => delete menuStocks[menuId]);
          form.change(`${stockLimitKey}.${productId}.${sizeId}.menus`, menuStocks);
        }
      }
    } else {
      menuStocks = {};
      menuIds.forEach((id) => (menuStocks[id] = 0));
      form.change(`${stockLimitKey}.${productId}.${sizeId}.menus`, menuStocks);
    }

    // update menu heading tree on specific menu selection on override form
    updateOverrideMenuIds(menuIds);
  }

  return (
    <div className="mb-4">
      <Row className="justify-content-between ">
        <Col>
          <FormGroup>
            <Label>Menus</Label>
            <Field
              name={`${stockLimitKey}.${product.productId}.${size.id}.menus`}
              onChange={(ids) => handleSelectedMenus(ids, form, product.productId, size.id)}
              treeData={menuTree}
              validate={(value) => {
                const ids = Object.keys(value || {});
                return ids?.length > 0 ? undefined : 'Menu is required';
              }}
              component={renderMenuTree as any}
            ></Field>
          </FormGroup>
        </Col>
        <Col className="col-auto pt-4 mt-1">
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => {
              updateProducts(product.productId, size.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </Col>
      </Row>

      <Row>
        <Col className="col-auto">
          <FormGroup className="m-0">
            <Table>
              <thead>
                <th>Menu</th>
                <th>Badge Text</th>
                <th>Menu Heading</th>
                <th>Standard $</th>
                <th>Override $</th>
              </thead>
              <tbody>
                {(Object.keys(values[stockLimitKey]?.[product.productId]?.[size.id]?.menus ?? {}) || []).map((menuId, index) => {
                  const menuHeadingTree = getMenuHeadingOptions(menuId, menus, menuHeadings);
                  return (
                    <tr key={index}>
                      <td width="25%">{menus[menuId]?.name}</td>
                      <Field name={`${stockLimitKey}.${product.productId}.${size.id}.menus.${menuId}`}>
                        {({ input, meta }) => {
                          return (
                            <>
                              <td width="25%">
                                <div className="form-group">
                                  <input id={`badgeText-${menuId}`} type="text" value={input.value.badgeText} className="form-control" onChange={(e) => input.onChange({ ...input.value, badgeText: e.target.value })} />
                                </div>
                              </td>
                              <td width="30%">
                                <div className="form-group">
                                  <select id={`menuHeadingId-${menuId}`} value={input.value?.menuHeadingId} className="form-control" onChange={(e) => input.onChange({ ...input.value, menuHeadingId: e.target.value ? e.target.value : '' })}>
                                    <option value="">Please Select</option>
                                    {map(menuHeadingTree, (menuHeading) => (
                                      <option key={menuHeading.id} value={menuHeading.id}>
                                        {menuHeading.label}
                                      </option>
                                    ))}
                                  </select>
                                  {meta.error?.menuHeadingId && <span className="d-block validate-form">{meta.error.menuHeadingId}</span>}
                                </div>
                              </td>
                              <td width="10%">
                                <div className="form-group">
                                  <input className="form-control" type="text" disabled value={Number(product.restaurants[restaurantId].price?.[size.id])} />
                                </div>
                              </td>
                              <td width="10%">
                                <div className="form-group">
                                  <input id={`price-${menuId}`} type="number" value={input.value.price} className="form-control" onChange={(e) => input.onChange({ ...input.value, price: e.target.value })} min={0} />
                                  {meta.error?.price && <span className="d-block validate-form">{meta.error.price}</span>}
                                </div>
                              </td>
                            </>
                          );
                        }}
                      </Field>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}
