import { Orders } from "./Order";
import { PaymentPriceAdjustmentPayload } from "./transaction";

export interface Operator {
  _key: string;
  name: string;
  restaurants: {
    [restaurantId: string]: boolean;
  };
  // restaurantId: string;
  code: string;
  enabled?: boolean;
  permissions?: number;
}

export interface Operators {
  [operatorId: string]: Operator;
}

export interface OperatorSession {
  _key?: string;
  date?: string;
  mealId?: string;
  operatorId?: string;
  restaurantId?: string;
  tillId?: string;
  transactionNumber: number;
  orderNumber: number;
  updatedAt?: number;
  orders?: Orders;
  sessionId?: string;
  voucher?: string;
  offlineMode?: boolean;
  tables?: string[];
  functionBookingId?: string;
  bookingId?: string;
  customerId?: string;
  paymentPriceAdjustmentsPayload?: PaymentPriceAdjustmentPayload[];
  isLoggedIn?: boolean;
  closeTabId?: string; // id of the tab which is being closed
  note?: string;
}

export const PermissionType = Object.freeze({
  wizbutlerTill: 1,
  issueRefunds: 2,
  moto: 4,
  tableBlocks: 8,
  widgetAvailability: 16,
  waitlistToggle: 32,
  flipFloorPlan: 64,
  paxLimits: 128,
  managerReport: 256,
  maestro: 512,
  payWithCash: 1024,
  manualTransaction: 2048,
  salesCoordinator: 4096,
  ccOverride: 8192,
  issueDeferredBookingVoucher: 16384,
  ai: 32768,
  menusOverride: 65536,
});

// export interface PermissionTypes {
//   [type: PermissionType]: string;
//   wizButlerTill: {
//     name: 'WizButler Till';
//     value: 'wizButlerTill';
//   };
//   issueRefunds: {
//     name: 'Issue Refunds';
//     value: 'issueRefunds';
//   };
//   moto: {
//     name: 'Moto';
//     value: 'moto';
//   };
//   tableBlocks: {
//     name: 'Table Blocks';
//     value: 'tableBlocks';
//   };
//   widgetAvailability: {
//     name: 'Widget Availability';
//     value: 'widgetAvailability';
//   };
//   waitlistToggle: {
//     name: 'Waitlist on/off';
//     value: 'waitlistToggle';
//   };
//   flipFloorPlan: {
//     name: 'Flip floor plan';
//     value: 'flipFloorPlan';
//   };
//   paxLimits: {
//     name: 'Pax limits';
//     value: 'paxLimits';
//   };
//   managerReport: {
//     name: "Manager's Report";
//     value: 'managerReport';
//   };
// }
