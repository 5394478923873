import { FieldRequirement } from "./Form";
import { IHash } from "./IHash";

export enum CustomerType {
  Individual = "Individual",
  Corporate = "Corporate",
}

export interface Profile {
  membershipNumber: string;
  dateOfBirth: string;
  customerGroupId: string;
}

// export interface Customer {
//   _key?: string;
//   id?: string;
//   type: CustomerType;
//   email: string;
//   mobile: string;
//   mobileCode?: string;
//   work?: string;
//   workCode?: string;
//   home?: string;
//   homeCode?: string;
//   // alternativePhone?: string;
//   // alternativePhoneCode?: string;
//   companyPhone?: string;
//   companyPhoneCode?: string;
//   titleId?: string;
//   level?: number;
//   firstName?: string;
//   firstName_low?: string;
//   lastName?: string;
//   lastName_low?: string;
//   tags?: any;
//   deleted?: boolean;
//   doNotEmail?: boolean;
//   company?: string;
//   company_low?: string;
//   companyEmail?: string;
//   occasionId?: string;
//   occupation?: string;
//   gender?: string;
//   address?: string;
//   street2?: string;
//   street1?: string;
//   country?: string;
//   state?: string;
//   postcode?: string;
//   suburb?: string;
//   dobYear?: string;
//   birthdayDay?: number;
//   birthdayMonth?: number;
//   birthdayYear?: number;
//   importedDate?: any;
//   dobMonth?: string;
//   dobDay?: string;
//   staffNotes?: string;
//   dateOfBirth?: number;
//   wineDessert?: string;
//   winePriceRange?: string;
//   winePreference?: string;
//   preferredTableId?: string;
//   dietaryRequirements?: Array<{ label: string; value: string }>;
//   allergies?: Array<{ label: string; value: string }>;
//   referralId?: string;
//   notes?: string;
//   ban?: {
//     operator: string;
//     enabled: boolean;
//     from: number;
//     to: number;
//   };
//   totalPointsEarned?: number;
//   totalPointsRedeemed?: number;
//   role?: string;

//   receiveMarketing?: boolean;
//   restaurantMarketingPreferences?: {
//     [restaurantId: string]: {
//       date: number;
//       enabled: boolean;
//       updatedAt: number;
//     };
//   }; // change to MarketingPreference after script has run
//   smsMarketingPreferences?: IHash<any>;
//   restaurants?: IHash<any>;
//   tablePreferences?: IHash<any>;
//   reference?: string;
//   dateJoined?: any;
//   pendingEmailState?: boolean;
//   cancels?: number;
//   visits?: number;
//   visitsAsGuest?: number;
//   noShows?: number;
//   lastVisit?: any;

//   // Membership related
//   /** @deprecated use isCrm instead */
//   isMember?: boolean;
//   isCrmUser?: boolean;        // denotes this customer is registered as a member
//   customerGroupId?: string;   // membership level
//   membershipNumber?: string;
//   companyMembershipNumber?: string;

//   // Company (corporate) related
//   /** @deprecated use type = CustomerType.Corporate */
//   corporateAccount?: boolean;
//   /** @deprecated use country and set type to CustomerType.Corporate */
//   corporateCountry?: string;
//   /** @deprecated use postCode and set type to CustomerType.Corporate */
//   corporatePostCode?: string;
//   /** @deprecated use suburb and set type to CustomerType.Corporate */
//   corporateSuburb?: string;
//   corporateAbn?: string;
// }

// Personal Information
export type PersonalInfo = {
  titleId?: string;
  firstName?: string;
  firstName_low?: string;
  lastName?: string;
  lastName_low?: string;
  gender?: string;
  dateOfBirth?: number;
  birthdayDay?: number;
  birthdayMonth?: number;
  birthdayYear?: number;
  dobYear?: string;
  dobMonth?: string;
  dobDay?: string;
  occupation?: string;
};

// Contact Information
export type ContactInfo = {
  mobile: string;
  email?: string;
  mobileCode?: string;
  work?: string;
  workCode?: string;
  home?: string;
  homeCode?: string;
  doNotEmail?: boolean;
};

// Address Information
export type AddressInfo = {
  address?: string;
  street1?: string;
  street2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  country?: string;
};

// Marketing Preferences
export type MarketingPreference = {
  date: number;
  enabled: boolean;
  updatedAt: number;
};

// Marketing Info
export type MarketingInfo = {
  receiveMarketing?: boolean;
  restaurantMarketingPreferences?: {
    [restaurantId: string]: MarketingPreference;
  };
  smsMarketingPreferences?: IHash<any>;
};

// Dining Preferences
export type DiningPreferences = {
  preferredTableId?: string;
  dietaryRequirements?: Array<{ label: string; value: string }>;
  allergies?: Array<{ label: string; value: string }>;
  wineDessert?: string;
  winePriceRange?: string;
  winePreference?: string;
  tablePreferences?: IHash<any>;
  occasionId?: string;
};

// Membership Info
export type MembershipInfo = {
  isCrmUser?: boolean;
  customerGroupId?: string;
  membershipNumber?: string;
  totalPointsEarned?: number;
  totalPointsRedeemed?: number;
};

// Visit Stats
export type VisitStats = {
  cancels?: number;
  visits?: number;
  visitsAsGuest?: number;
  noShows?: number;
  lastVisit?: any;
};

// Ban Information
export type BanInfo = {
  operator: string;
  enabled: boolean;
  from: number;
  to: number;
};

// Corporate-specific information
export type CorporateInfo = AddressInfo & ContactInfo & MembershipInfo & {
    company?: string;
    company_low?: string;
    abn?: string;
    customerIds?: string[];
  };

// Base customer with common fields for both types
export type Customer = PersonalInfo &
  ContactInfo &
  AddressInfo &
  MarketingInfo &
  DiningPreferences &
  MembershipInfo &
  VisitStats & {
    _key?: string;
    id?: string;
    tags?: any;
    deleted?: boolean;
    level?: number;
    notes?: string;
    staffNotes?: string;
    referralId?: string;
    reference?: string;
    importedDate?: any;
    pendingEmailState?: boolean;
    restaurants?: IHash<any>;
    ban?: BanInfo;
    role?: string;
    dateJoined?: any; // what is this? date customer became member | date customer subscribed for marketing ??
    type?: CustomerType;
    corporateInfo?: CorporateInfo; // corporate information for this customer

    // Legacy fields - to be deprecated
    /** @deprecated use mobile | work | home */
    companyPhone?: string;
    /** @deprecated use  mobileCode | workCode | homeCode */
    companyPhoneCode?: string;
    /** @deprecated use email */
    companyEmail?: string;
    /** @deprecated use abn instead */
    corporateAbn?: string;
    /** @deprecated use type = CustomerType.Corporate */
    corporateAccount?: boolean;
    /** @deprecated use country and set type to CustomerType.Corporate */
    corporateCountry?: string;
    /** @deprecated use postcode and set type to CustomerType.Corporate */
    corporatePostCode?: string;
    /** @deprecated use suburb and set type to CustomerType.Corporate */
    corporateSuburb?: string;
    /** @deprecated use isCrmUser instead */
    isMember?: boolean;
    /** @deprecated use corporateInfo.membershipNumber instead */
    companyMembershipNumber?: string;
  };

export interface Customers {
  [key: string]: Customer;
}

export interface CustomerTag {
  customerTagCatagoriesId: string;
  enabled: boolean;
  id: string;
  important: boolean;
  name: string;
}

export interface CustomerTagCategory {
  color: string;
  enabled: boolean;
  id: string;
  name: string;
}

export interface EmailList {
  emailListId: string;
}

export interface CustomerProfileConfiguration {
  birthday?: FieldRequirement;
  dateOfBirth?: FieldRequirement;
  addYearToBirthday?: FieldRequirement;
  gender?: FieldRequirement;
  country?: FieldRequirement;
  state?: FieldRequirement;
  postcode?: FieldRequirement;
  allergies?: FieldRequirement;
  dietaryRequirements?: FieldRequirement;
  winePreferences?: FieldRequirement;
  winePriceRange?: FieldRequirement;
}

export interface RestaurantCrmSetting {
  configuration?: CustomerProfileConfiguration;
  file?: {
    bucket: string;
    fullPath: string;
  };
  staticMapURL?: string;
  termsAndConditions?: string;
  useGroupWebsite?: boolean;
}

export enum RequestOTPOption {
  Login = "Login",
  Register = "Register",
}
