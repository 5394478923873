import { Docket, DocketItem, Order, OrderItem } from "./Order";

export enum OMSDocketGeneratedFrom {
  Docket = 1,
  Order = 2,
}

export type OMSDisplayDocket = {
  docketGeneratedFrom: OMSDocketGeneratedFrom;
} & (DocketFromDocket | DocketFromOrder);

export type DocketFromOrder = {
  docketGeneratedFrom: OMSDocketGeneratedFrom.Order;
  printerAreaIds: string[];
  paid: boolean;
  docketItems: DocketFromOrderDocketItems[];
  tableNo: string;
  time?: string;
  hidden?: {
    one: boolean;
    prep: boolean;
    serve: boolean;
  };
} & Order;

export type DocketFromDocket = {
  docketGeneratedFrom: OMSDocketGeneratedFrom.Docket;
  docketGenerationTime: number;
  docketId: string;
  docketItems: DocketFromDocketDocketItems[];
} & Omit<Docket, "docketItems">;

export type DocketFromDocketDocketItems = { docketId: string; docketItemId: string } & DocketItem;
export type DocketFromOrderDocketItems = { heading: string; customerNames?: string[] } & OrderItem;
