import 'firebase/compat/auth';
import 'firebase/compat/database';
import { useEffect } from 'react';
import useDimensions from 'react-cool-dimensions';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Spinner } from 'reactstrap';
import useMenus from '../../hooks/useMenus';
import useOperators from '../../hooks/useOperators';
import useProductAmmendments from '../../hooks/useProductAmmendments';
import useRestaurants from '../../hooks/useRestaurants';
import useUser from '../../hooks/useUser';
import { setOMSWidth } from '../../store/reducers/rootSlice';
import Offsidebar from './Offsidebar';
import OmsHeader from './OmsHeader';
import OmsSelector from './OmsSelector';
import useLabels from '../../hooks/useLabels';

const Base = (props) => {
  const [loading] = useUser();
  const [loadingMenus] = useMenus(loading);
  useProductAmmendments(loading);
  useRestaurants(loading);
  useOperators(loading);
  useLabels(loading);

  const dispatch = useDispatch();
  const { observe, width: omsWidth } = useDimensions();

  useEffect(() => {
    dispatch(setOMSWidth(Math.floor(omsWidth)));
  }, [omsWidth]);

  if (loading || loadingMenus) {
    return (
      <div className="text-center" style={{ padding: '30px 0' }}>
        <Spinner size="lg" color="white">{""}</Spinner>
      </div>
    );
  }
  return (
    <div className="wrapper oms">
      <OmsHeader {...props} />
      <div ref={observe} style={{ margin: '0 10px' }}>
        <OmsSelector {...props} />
      </div>
      <section className="section-container" style={{ marginTop: '-10px', marginLeft: '10px', marginRight: '10px' }}>
        {props.children}
      </section>
      <Offsidebar />
      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
    </div>
  );
};

export default Base;
