import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { utils } from "../resbutler-utils";
import { Labels } from "../resbutler-utils/types/Labels";
import { Meals } from "../resbutler-utils/types/Meals";

export function getConfig() {
  const dataUnserialized = window.localStorage.getItem("butler:data");
  return dataUnserialized ? JSON.parse(dataUnserialized) : null;
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const buildDataFromDocs = (docs) => {
  const data = {};
  docs.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  return data;
};

export const catchExceptionCallback = (error) => {
  let error1 = error;
  const className = "error";
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      error1 = error.response.data.message;
    } else {
      error1 = error.response.data;
    }
    // if (error.stack && error.response.status === 500) {
    //     stacktrace = error.stack;
    // }
  } else if (error.message) {
    error1 = error.message;
    // if (error.stack) {
    //     stacktrace = error.stack;
    // }
  }
  toast[className](error1, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
  return error;
};

export function getMealsFiltered(date, restaurantId, labels: Labels, meals: Meals, zoneId): Meals {
  const now = moment.tz(zoneId);
  let mealsFiltered = {} as Meals;
  const dateMaestro = moment.tz(date, "YYYYMMDD", zoneId).endOf("day");
  if (!now.isSameOrAfter(dateMaestro) && labels) {
    const mealLabels = utils.getMealLabelIds(labels, zoneId, date, restaurantId);
    mealsFiltered = _.pickBy(meals, (meal, mId) => meal.restaurantId === restaurantId && meal.enabled && _.findKey(mealLabels, (ml: any) => ml.mealId === mId) !== undefined);
  } else {
    mealsFiltered = _.pickBy(meals, (meal) => meal.restaurantId === restaurantId && meal.enabled);
  }

  return mealsFiltered;
}
