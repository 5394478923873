import { FC, useEffect, useState } from "react";
import { Button, ButtonGroup } from "../WizElements/Elements";

const styles = `
  .multi-toggle-slider {
    position: absolute;
    background: var(--primary, var(--bs-primary));
    border-radius: 0.25rem;
    height: 100%;
    pointer-events: none;
    transition: all 300ms ease-in-out;
  }

  .btn.btn-outline-primary.custom-toggle {
    color: var(--dark, var(--bs-dark));
    background: transparent !important;
    transition: color 300ms ease-in-out;
  }

  .btn.btn-outline-primary.custom-toggle.active {
    color: var(--white, var(--bs-white, white));
    background: transparent !important;
  }
`;

export type MultiToggleOption = {
  value: string | number;
  label: string;
  icon?: any;
};

interface MultiToggleProps {
  options: MultiToggleOption[];
  color?: string;
  size?: string;
  classNames?: string;
  selectedOption?: string | number;
  onSelectOption?: (value: string | number) => void;
}

/**
 * Render multi toggle buttons with text and icons and directional slide animation
 * 
 * @example
 * const options = [
    { value: "1", label: "Docket", icon: LayoutPanelTop },
    { value: "2", label: "Table", icon: DTable },
    { value: "4", label: "Basket", icon: ShoppingBasket },
  ];
 * <MultiToggle options={options} color="primary" size="md" />
 */
const MultiToggle: FC<MultiToggleProps> = ({ options, color = "primary", size = "md", classNames = "", selectedOption = "1", onSelectOption }) => {
  const itemWidth = 100 / options.length;
  const [prevPosition, setPrevPosition] = useState(0);
  const [sliderStyle, setSliderStyle] = useState({
    left: `${prevPosition}%`,
    width: `${itemWidth}%`,
    transition: "",
  });

  useEffect(() => {
    const currentIndex = options.findIndex((opt) => opt.value === selectedOption);
    const currentPosition = currentIndex * itemWidth;

    if (prevPosition !== currentPosition) {
      setSliderStyle({
        left: `${currentPosition}%`,
        width: `${itemWidth}%`,
        transition: "left 300ms ease-in-out",
      });

      setPrevPosition(currentPosition);
    }
  }, [selectedOption, options, itemWidth, prevPosition]);

  const sliderColor = color ? `bg-${color}` : "";
  return (
    <>
      <style>{styles}</style>
      <ButtonGroup className={`bg-light rounded ${classNames} container-fluid`} style={{ position: "relative", overflow: "hidden" }}>
        <div className={`multi-toggle-slider ${sliderColor}`} style={sliderStyle} />
        {options.map(({ value, label, icon: Icon }) => (
          <Button //
            key={value}
            outline
            color={color}
            size={size}
            active={selectedOption === value}
            onClick={() => {
              if (typeof onSelectOption === "function") onSelectOption(value);
            }}
            className={`d-flex align-items-center justify-content-center custom-toggle border-0 text-nowrap py-2 px-4 ${selectedOption === value ? "text-white" : ""}`}
            style={{ flex: 1, position: "relative", zIndex: 1, outline: "none", boxShadow: "none", transition: "color 600ms ease-out" }}
          >
            {Icon && <Icon size={size === "sm" ? 14 : size === "lg" ? 20 : 16} className="mr-2 me-2" />}
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

export default MultiToggle;
